const Constant = {
    USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
    USER_SIGNED_IN: 'signedIn',
    NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
    USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
    SIGNED_IN: 'signedIn',
    CONFIRM_SIGN_UP: 'confirmSignUp',
    LOGIN_FAILED_MSG: 'Login failed.',
    ERROR_OCCURRED_MSG: 'エラーが発生しました。',
    AUTH_MODE_API_KEY: 'API_KEY',

    ADMIN: { ON: 1, OFF: 0 },

    TAB_FORM_MANAGEMENT: { FORM: 'form', FIELD: 'field' },

    PROJECT_PERMISSION: {
        BASE: 0,
        MEMBER: 1,
        MANAGER: 2
    },

    FORM_FIELD_MANAGEMENT_ACTION_FLAG: {
        CREATE_NEW_FORM: 'CREATE_NEW_FORM',
        EDIT_FORM: 'EDIT_FORM',
        DELETE_FORM: 'DELETE_FORM',
        UPDATE_FIELD_LIST: 'UPDATE_FIELD_LIST',
        UPDATE_LIST_FIELD_MANAGEMENT: 'UPDATE_LIST_FIELD_MANAGEMENT'
    },

    USAGE_TYPE: {
        CORPORATION: 1,
        USER: 2,
        TICKET: 3,
        PROJECT: 4,
        THREAD_CHAT: 5,
        POST_DETAIL: 6
    },

    PERMISSION_TYPE: {
        USER: 0,
        ADMIN: 1
    },
    //shareRange : Internal
    POST_PUBLIC_FLAG: {
        PUBLIC: 1,
        INTERNAL: 0
    },

    SERVER_ERROR: 'SYSMSG001',
    CHANGE_MANAGER_LAST_ERROR: 'USER01MSG009',
    DATA_CHANGED_PLEASE_RELOAD_ERROR: 'COMMSG030',
    EXISTS_B2B_CUSTOMER_ERROR: 'USER04MSG031',
    ADD_USER_MAX_GENERAL_USER_ERROR: 'USER14MSG011',
    CREATE_TICKET_MAX_GENERAL_ERROR: 'USER14MSG022',
    SUB_DOMAIN_DUPLICATION: 'COMM01MSG04',
    MAX_GENERAL_ID_ERROR: 'USER03MSG007',
    MSG_CM_DELETED_TICKET: 'MSG-CM-DELETED-TICKET',
    MSG_CM_SUBDOMAIN_DATA_DUPLICATION: 'MSG-CM-SUBDOMAIN-DATA-DUPLICATION',
    MSG_CM_EMAIL_DATA_DUPLICATION: 'MSG-CM-EMAIL-DATA-DUPLICATION',

    CONTROLLABLE_ERROR_TYPE: ['CognitoError', 'UnauthorizedError', 'ApiError'],

    LIMIT_VALUE_SETTING: {
        MAX_NUM_OF_TICKET: 99999999,
        MAX_NUM_OF_STORAGE: 9999.999
    },

    LIMIT_VALUE_ERROR: {
        MAX_NUM_OF_GENERAL_USER: 'USER14MSG010'
    },

    LIMIT_VALUE_NAME: {
        MAX_NUM_OF_GENERAL_USER: 'maxNumOfGeneralUser',
        MAX_NUM_OF_GUEST_USER: 'maxNumOfGuestUser',
        MAX_NUM_OF_CUSTOMER: 'maxNumOfCustomer',
        MAX_NUM_OF_GENERAL_PROJECT: 'maxNumOfGeneralProject',
        MAX_NUM_OF_GUEST_PROJECT: 'maxNumOfGuestProject',
        MAX_NUM_OF_GUEST_DESK: 'maxNumOfGuestDesk',
        MAX_NUM_OF_TICKET: 'maxNumOfTicket',
        MAX_NUM_OF_STORAGE: 'maxNumOfStorage'
    },

    DAYS_HARD_DELETE_TICKET: 30,

    BLANK_IMAGE: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
    // AVATAR_DEFAULT: '/assets/image/imgUser.png',
    AVATAR_DEFAULT: '/assets/image/icon-user-default.svg',
    CHATBOT_DEFAULT: '/assets/image/chatbot.svg',
    USER_PROFILE_AVATAR_DEFAULT: '/assets/image/avatarUser.png',
    LOGO_FILE_NAME_DEFAULT: 'accela_logo.png',
    LOGO_DEFAULT: '/assets/image/accela-technology.png',
    PROJECT_ICON_DEFAULT: '/assets/image/project-icon.png',

    LOGIN_ROUTER: '/login',
    LOGIN_ROUTER_INTERNAL_PARTNER: '/login/:orgCode/p/:orgInvitation',
    LOGIN_ROUTER_INTERNAL_SUPPORTED: '/login/:orgCode/c/:orgInvitation',

    CREATE_ORGANIZATION_ROUTER: '/solutiondesk/organization/create',
    CREATE_ORGANIZATION_SUCCESS_ROUTER: '/solutiondesk/organization/success',
    CONFIRM_CREATE_ORGANIZATION_ROUTER: '/solutiondesk/organization/confirm/',
    LIST_TICKET_ROUTER: '/',
    APPROVE_SUCCESS_ROUTER: '/guest-project/approve',
    REJECT_SUCCESS_ROUTER: '/guest-project/reject',
    PUBLIC_KNOWLEDGE_LIST_ROUTER: '/:subDomain/:projectId/kb',
    PUBLIC_KNOWLEDGE_DETAIL_ROUTER: '/:subDomain/:projectId/kb/:id',
    SHARE_TICKET_ROUTER: '/share/:subDomain/:projectId/ticket/:id',

    CREATE_GUEST_CONTRACT_ROUTER: '/guest-contract/create/',
    CONFIRM_CREATE_GUEST_CONTRACT_ROUTER: '/guest-contract/success',

    USER_MANAGEMENT_ROUTER: '/system/userManagement',
    PROJECT_USER_MANAGEMENT_ROUTER: '/project/user-management',
    UPDATE_ORG_PROFILE_ROUTER: '/system/organization-profile',
    PROJECT_MANAGEMENT_ROUTER: '/system/project-management',
    USER_PROFILE_ROUTER: '/user',
    APPROVE_PAGE_ROUTER: '/guest-contract/approve/',
    FORM_MANAGEMENT_ROUTER: '/form/formManagement',
    IFRAME_LOGIN_ROUTER: '/iframe/login',
    FORGOT_PASSWORD_ROUTER: '/forgot-password',
    CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',

    PROJECT_BASE_NAME: 'ベースプロジェクト',
    PROJECT_GUEST_CUSTOMER_NAME: 'ゲストカスタマープロジェクト',
    TICKET_DUMMY_CUSTOMER_VALUE: 'dummy_customer',

    SHARE_OPTION: {
        INTERNAL_ORG: 1,
        SPECIFIC_ORG: 2,
        B2B_CUSTOMER_ORG: 3,
        B2B_HELPDESK_ORG: 4,
        ALL_ORG: 5
    },

    CORPORATION_ACTIVE: {
        WAITING_APPROVE: 0,
        ACTIVE: 1,
        REJECT: 2,
        BLOCK: 3,
        DELETED: 4
    },

    USER_TYPE: {
        // GENERAL: 1,
        // GUEST: 2,
        // INTERNAL: 3,
        // GUEST_CORP: 4,
        GENERAL: 'GENERAL',
        // GUEST: 'GUEST',
        CUSTOMER: 'BTOC',
        INTERNAL: 'INTERNAL'
    },

    GUEST_USER_FLAG: {
        TRUE: 1,
        FALSE: 0
    },

    PROJECT_TYPE: {
        // GUEST: 1,
        // GENERAL: 2,
        // BASE: 3,
        // GUEST_CORP: 4,
        // GUEST_DESK: 5
        GENERAL: 'PRJ_GENERAL',
        // PRIVATEDESK: 'PRJ_PRIVATEDESK',
        // PUBLICDESK: 'PRJ_PUBLICDESK',
        BASE: 'PRJ_BASE',
        // CUSTOMER: 'PRJ_CUSTOMER',
        CUSTOMER: 'PRJ_BTOC',
        // GUEST: 'PRJ_GUEST',
        // GUESTDESK: 'PRJ_GUESTDESK',

        USER_INFO: 'USER_INFO',
        USER_CUSTOMER: 'USER_CUSTOMER',
        PRJ_BUSINESS_CUSTOMER: 'PRJ_BUSINESS_CUSTOMER',
        PROJECT_CHATBOT_CATEGORIES: 'PRJ_CHATBOT_CATEGORIES'
    },

    // Note: name display for detail ticket, list ticket, create delegate ticket
    PROJECT_BASE_DISPLAY_NAME: '組織内個人問い合わせ',

    INVITE_PROJECT_TYPE: {
        GUEST: 'PRJ_GUEST',
        GUESTDESK: 'PRJ_GUESTDESK'
    },

    DESK: { ON: 1, OFF: 0 },

    PUBLIC: { ON: 1, OFF: 0 },

    PROJECT_SERVICE_TYPE: {
        DESK_OFF: '一般プロジェクト',
        DESK_ON_PUBLIC_OFF: '組織内サービスデスクプロジェクト',
        DESK_ON_PUBLIC_ON: '公開サービスデスクプロジェクト'
    },

    DEFAULT_LIMIT: 999999,

    THREAD_TYPE: {
        CUSTOMER: 1,
        INTERNAL: 2,
        ESCALATION: 3,
        DELEGATE: 4
    },
    EVENT_EMITTER_TYPE: {
        GET_TICKET_LIST: 'GET_TICKET_LIST',
        GET_TICKET_LIST_WITH_CHANGE_PROJECT: 'get_ticket_list_with_change_project',
        GET_TICKET_LIST_WITH_CHANGE_MENU: 'get_ticket_list_with_change_menu',
        GET_TICKET_LIST_WITH_ADD_TICKET: 'get_ticket_list_with_add_ticket',
        GET_LIST_USER_IN_PROJECT: 'get_list_user_in_project'
    },

    TYPE_GET_TICKET_LIST: {
        CUSTOMER: 'customer',
        HELPDESK: 'helpdesk'
    },

    MENU_SELECT_TYPE: {
        TICKET_LIST_CUSTOMER_SIDE: 'customer',
        TICKET_LIST_HELP_DESK_SIDE: 'helpdesk',
        SYSTEM: 'system',
        USER_PROFILE: 'user_profile',
        PROJECT_SETTING: 'project_setting'
    },

    POST_TYPE: {
        MSG: 'msg',
        IMAGE: 'image',
        FILE: 'file',
        END_LIVE_CHAT_MESSAGE: 'end_live_chat_message',
        SYSTEM_AUTO_END_LIVE_CHAT: 'system_auto_end_live_chat',
        SUPPORT_MSG: 'support-msg', // Live chat with agent,
        CHAT_BOT_CONNECTED_WITH_AGENT: 'chat_bot_connected_with_agent',
        CHAT_BOT_NOT_FOUND_AGENT: 'chat_bot_not_found_agent',
        ANONYMOUS_END_LIVE_CHAT: 'ANONYMOUS_END_LIVE_CHAT',
        TICKET_REMOVED: 'TICKET_REMOVED'
    },

    ACTION_CHANGE_LIST_DATA_TYPE: {
        INITIAL: 1,
        ADD: 2,
        PUSH: 3,
        REPLACE: 4,
        CONCAT: 5
    },

    COLOR_THREAD_CHAT: {
        CUSTOMER_TAB: '#2E3138',
        INTERNAL_TAB: '#3161B1',
        ESCALATE_TAB: '#A37E29',
        REFERENCE_TAB: '#68C1E7',

        CUSTOMER_CHAT: '#CECFD1',
        INTERNAL_CHAT: '#A4C5FF',
        ESCALATE_CHAT: '#FEE8B6',
        REFERENCE_CHAT: '#E3F6FE'
    },

    THREAD_CHAT_TYPE: {
        CUSTOMER_THREAD: 1,
        INTERNAL_THREAD: 2,
        ESCALATION_THREAD: 3,
        KNOWLEDGE_THREAD: 4,
        ANONYMOUS_SUPPORT_THREAD: 5
    },

    TICKET_TYPE: {
        QA: 1,
        DELEGATE: 2,
        NOTICE: 3,
        ESCALATION: 4,
        INVITATION_GUEST_PROJECT: 5,
        INVITATION_GUEST_DESK_PROJECT: 6,

        APPROVE: 7,

        INTERNAL_QA: 8,
        EXTERNAL_QA: 9,
        REQUEST_EXTERNAL_DESK: 10,
        APPROVE_GUEST_PROJECT: 11,
        APPROVE_GUEST_DESK_PROJECT: 12,
        BROADCAST_PARENT: 13,
        BROADCAST_CHILD: 14,

        INVITATION_GUEST_CUSTOMER: 15,
        KNOWLEDGE: 16,
        TASK: 17,
        MASTER_KNOWLEDGE: 18,
        REQUEST_EXTERNAL_CUSTOMER: 19,
        PDF_FILE_KNOWLEDGE: 20,
        PDF_PAGE_KNOWLEDGE: 21
    },

    HELPDESK_CREATE_TICKET_TYPE: {
        NOTICE: 1,
        DELEGATE: 2,
        INVITATION_GUEST: 3,
        TASK: 4
    },

    TICKET_INVITATION_GUEST: "TICKET_INVITATION_GUEST",

    QA_CREATE_TICKET_TYPE: {
        QA: 1,
        INVITATION: 2
    },

    LIVE_OR_ARCHIVED: {
        // DRAFT: 'DRAFT',
        LIVE: 'LIVE',
        ARCHIVED: 'ARCHIVED'
    },

    TICKET_PUBLIC: {
        DRAFT: 'DRAFT',
        PUBLIC: 'PUBLIC',
        PRIVATE: 'PRIVATE'
    },

    CHAT_POST_STATUS: {
        UN_READ: 1,
        READ: 2,
        DELETE_READ: 4,
        DELETE: 5
    },

    ANONYMOUS_CHATBOT_ACTION: {
        DELETE_MSG: 'DELETE_MSG',
        CREATE_MSG: 'CREATE_MSG',
        CREATE_THUMBNAIL: 'CREATE_THUMBNAIL'
    },

    ANONYMOUS_TYPE: {
        CHATBOT: 'CHATBOT',
        IFRAME: 'IFRAME',
        CHATBOT_LIVECHAT: 'CHATBOT_LIVECHAT'
    },

    SYS_FIELD: {
        ATTACHMENTS: 'attachments',
        OUTLINE: 'outline',
    },

    CORPORATION_CONTRACT: {
        TYPE: {
            TRIAL: 1,
            STANDARD: 2,
            PREMIUM: 3,
            GUEST: 4
        },
        DATA: {
            DEFAULT_EXPIRE_TIME: 365 * 24 * 60 * 60 * 1000,
            TRIAL_EXPIRE_TIME: 30 * 24 * 60 * 60 * 1000,
            DEFAULT_NUM_OF_AGENT: 0,
            DEFAULT_MAX_AGENT: 20,
            DEFAULT_NUM_OF_GUEST_DESK_PROJECT: 0,
            DEFAULT_MAX_NUM_OF_GUEST_DESK_PROJECT: 0,
            DEFAULT_NUM_OF_GENERAL_USER: 0,
            DEFAULT_MAX_GENERAL_USER: 20,
            DEFAULT_NUM_OF_TICKET: 0,
            DEFAULT_MAX_NUM_OF_TICKET: 20 * 20000, // Max general user * 20000
            DEFAULT_NUM_OF_STORAGE: 0,
            DEFAULT_MAX_NUM_OF_STORAGE: 20 * 5 * 1024 * 1024 * 1024, // Max general user * 5 GB (Unit is B)
            DEFAULT_NUM_OF_GENERAL_PROJECT: 0,
            DEFAULT_MAX_OF_GENERAL_PROJECT: 0,
            DEFAULT_NUM_OF_PROJECT_PUBLIC_ON: 0,
            DEFAULT_MAX_OF_PROJECT_PUBLIC_ON: 0,
            DEFAULT_NUM_OF_PROJECT_DESK_ON: 0,
            DEFAULT_MAX_OF_PROJECT_DESK_ON: 0
        }
    },

    SECRET_KEY_ENCRYPT_INVITATION_DATA: 'Omi2021',
    SECRET_KEY_ENCRYPT_GENERATE_DATA: 'Omi2022',

    INVITATION_FLAG: {
        INPROCESS: 0, // default
        WAITING_APPROVE: 1,
        INVITE_SUCCESS: 2,
        INVITE_REJECTED: 3,
        INVITE_DELETED: 4
    },

    APPROVE_FLAG: {
        INPROCESS: 0,
        APPROVE: 1,
        REJECTED: 2
    },

    CLOUD_FRONT_URL_DEV: 'https://d1ltmpvqq83o9p.cloudfront.net',
    S3_FILE_TYPE: {
        TICKET_FILE: 1,
        CHAT_POST_FILE: 2,
        USER_PROFILE_FILE: 3,
        CORPORATION_LOGO_LOGIN_FILE: 4,
        CORPORATION_LOGO_PROFILE_FILE: 5
    },
    S3_URL_EXPIRY_TIME: 1,
    S3_URL_AES_ENCODE_KEY: 'accelaBaDesk',
    PASSWORD_AES_ENCODE_KEY: 'accelaBaDesk',
    SUBSCRIPTION_TYPE: {
        CREATE: 'create',
        UPDATE: 'update',
        DELETE: 'delete'
    },

    PANEL_TYPE: {
        LIST_TICKET: 'list_ticket',
        CHAT_THREAD: 'chat_thread',
        DETAIL_TICKET: 'detail_ticket',
        ADD_TICKET: 'add_ticket',
        DETAIL_TICKET_APPROVE: 'detail_ticket_approve',

        FORM_MANAGEMENT: 'form_management',
        LIST_TICKET_KNOWLEDGE: 'list_ticket_knowledge',

        HELPDESK_TICKET_LIST: 'helpdesk_ticket_list',
        HELPDESK_CHATTHREAD: 'helpdesk_chatThread',
        HELPDESK_TICKET_DETAIL: 'helpdesk_ticket_detail',
        HELPDESK_TICKET_LIST_DRILLDOWN_AND_DETAIL_SEARCH:
            'helpdesk_ticket_list_drilldown_and_detail_search',

        CUSTOMER_TICKET_LIST: 'customer_ticket_list',
        CUSTOMER_CHATTHREAD: 'customer_chatThread',
        CUSTOMER_TICKET_DETAIL: 'customer_ticket_detail',

        KNOWLEDGE_TICKET_LIST: 'knowledge_ticket_list',
        KNOWLEDGE_HELPDESK_TICKET_LIST: 'knowledge_helpdesk_ticket_list',
        KNOWLEDGE_CHATTHREAD: 'knowledge_chatThread',
        KNOWLEDGE_TICKET_DETAIL: 'knowledge_ticket_detail',
        KNOWLEDGE_HELPDESK_TICKET_DETAIL: 'knowledge_helpdesk_ticket_detail',
        KNOWLEDGE_CREATE_TICKET: 'knowledge_create_ticket',
        KNOWLEDGE_HELPDESK_DRILLDOWN_AND_DETAIL_SEARCH:
            'knowledge_helpdesk_drilldown_and_detail_search',
        KNOWLEDGE_PRJ_INUSE_DRILLDOWN_AND_DETAIL_SEARCH:
            'knowledge_prj_inuse_drilldown_and_detail_search',
        KNOWLEDGE_UPLOAD_CSV: 'knowledge_upload_csv',

        SYSTEM_MANAGEMENT: 'system_management',
        SYSTEM_USER_MANAGEMENT: 'system_user_management',
        SYSTEM_USER_PROFILE: 'system_user_profile',
        SYSTEM_PROJECT_MANAGEMENT: 'system_project_management',
        SYSTEM_PROJECT_PROFILE_MANAGEMENT: 'system_project_profile_management',
        SYSTEM_PROJECT_USER_MANAGEMENT: 'system_project_user_management',
        SYSTEM_PROJECT_CUSTOMER_MANAGEMENT: 'system_project_customer_management',
        SYSTEM_CORPORATION_CUSTOMER_MANAGEMENT: 'system_corporation_customer_management',
        SYSTEM_PROJECT_SETTING_IFRAME: 'system_project_setting_iframe',
        SYSTEM_PUBLIC_KNOWLEDGE_SITE_SETTING: 'system_public_knowledge_site_setting',
        SYSTEM_PROJECT_SETTING_CHATBOT: 'system_project_setting_chatbot',
        SYSTEM_PROJECT_SETTING_AI: 'system_project_setting_ai',
        SYSTEM_NORMAL_CONTRACT_DETAIL: 'system_normal_contract_detail',

        PROJECT_MANAGEMENT: 'project_management',
        ORGANIZATION_MANAGEMENT: 'organization_management',
        FORMAT_MANAGEMENT: 'format_management',
        PERSONAL_MANAGEMENT: 'personal_management',

        SYSTEM_ORG_PROFILE: 'system_org_profile',
        SYSTEM_FIELD_MANAGEMENT: 'system_field_management',
        LIST_NOTIFICATION: 'list_notification',
        ROOT_LIST_ORG: 'root_list_org',
        ROOT_ORG_EDIT_CONTRACT: 'root_edit_org_contract',
        QA_CATEGORY_SETTING: 'qa_category_setting',
        PREVIEW_CHATBOT: 'preview_chatbot',
        PRODUCT_MANAGEMENT: 'product_management',
        VERSION_MANAGEMENT: 'version_management',
        OPEN_CHAT_AI: 'open_chat_ai'
    },

    TAB_PANEL_TYPE: {
        QA_NEW_TICKET_TAB: 'QA_NEW_TICKET_TAB',
        HELPDESK_NEW_TICKET: 'HELPDESK_NEW_TICKET',
        SYSTEM_USER_PROFILE: 'system_user_profile',
        SYSTEM_ORG_PROFILE: 'system_org_profile',
        SYSTEM_PROJECT_MANAGEMENT: 'system_project_management',
        SYSTEM_USER_MANAGEMENT: 'system_user_management',
        SYSTEM_FIELD_MANAGEMENT: 'system_field_management',
        SYSTEM_PROJECT_USER_MANAGEMENT: 'system_project_user_management',
        SYSTEM_PROJECT_PROFILE_MANAGEMENT: 'system_project_profile_management',
        SYSTEM_PROJECT_CUSTOMER_MANAGEMENT: 'system_project_customer_management',
        SYSTEM_CORPORATION_CUSTOMER_MANAGEMENT: 'system_corporation_customer_management',
        KNOWLEDGE_CREATE_TICKET: 'knowledge_create_ticket',
        KNOWLEDGE_TICKET_DETAIL: 'knowledge_ticket_detail',
        KNOWLEDGE_TICKET_DETAIL_CLOSE_TAB: 'knowledge',
        ROOT_ORG_INFO: 'ROOT_ORG_INFO',
        ROOT_ORG_EDIT_ORG_INFO: 'ROOT_ORG_EDIT_ORG_INFO',
        SYSTEM_PUBLIC_KNOWLEDGE_SITE_SETTING: 'system_public_knowledge_site_setting',
        SYSTEM_PROJECT_SETTING_CHATBOT: 'system_project_setting_chatbot',
        SYSTEM_PROJECT_SETTING_AI: 'system_project_setting_ai',
        SYSTEM_NORMAL_CONTRACT_DETAIL: 'system_normal_contract_detail',
        QA_CATEGORY_SETTING: 'qa_category_setting',
        NEW_TAB_CHAT_AI: 'new_tab_chat_ai',
        TAB_DUPLICATE_TICKET: 'Duplicate_ticket_'
    },

    // User mngユーザー管理, user profileユーザプロフィール, Proj mngプロジェクト管理,プロジェクトのユーザー管理、
    // org profile組織プロフィール,  Field Form Mngフォーマット編集、

    // ・[Helpdesk Ticket list]
    // ・[Helpdesk Ticket detail]
    // ・[Helpdesk ChatThread]
    // ・[QA Ticket list]
    // ・[QA Ticket detail]
    // ・[QA ChatThread]
    // ・[Knowledge list]
    // ・[Knowledge ChatThread]
    // ・[Knowledge detail]
    // ・[Edit Field]
    // ・[Edit Format]
    // ・[Quản lý User]
    // ・[Quản lý Project]
    // ・[Edit User Profile]

    SCREEN_LIST: {
        B2C_IN_ORG: 'B2C_IN_ORG',
        B2C_IN_PROJECT: 'B2C_IN_PROJECT'
    },

    APPROVE_PAGE_ACTION_TYPE: {
        REJECT: 'reject',
        APPROVE: 'approve'
    },

    // usage {ORG|PRJ|USER}
    SYSTEM_USAGE_TYPE: {
        CORPORATION: 1,
        PROJECT: 2,
        USER: 3,
        THREAD: 4,
        TICKET: 5,
        STORAGE: 6
    },

    ACTIVE_FLAG: {
        ACTIVE: 1,
        INACTIVE: 0,
        DELETED: 2,
        REJECT: 3,
        NOT_INVITED: 4
    },

    USERMANAGEMENT_ACTION_FLAG: {
        DELETE_USER_IN_ORG: 'delete',
        RESTORE: 'restore',
        CREATE_AND_UPDATE_USER_IN_CORP: 'CREATE_AND_UPDATE_USER_IN_CORP',
        ADD_AND_EDIT_USER_IN_PROJECT: 'ADD_AND_EDIT_USER_IN_PROJECT',
        DELETE_USER_IN_PROJECT: 'DELETE_USER_IN_PROJECT',
        DELETE_B2C_CUSTOMER_IN_CORPORATION: 'DELETE_B2C_CUSTOMER_IN_CORPORATION',
        ACTIVE_USER_AFTER_FIRST_LOGIN: 'ACTIVE_USER_AFTER_FIRST_LOGIN'
    },

    CUSTOMERMANAGEMENT_ACTION_FLAG: {
        CREATE_AND_UPDATE_CUTOMER_IN_CORP: 'CREATE_AND_UPDATE_CUTOMER_IN_CORP'
    },

    AUTO_TRANSLATION_FLAG: {
        TRUE: 1,
        FALSE: 0
    },

    PRIVILEGE_PROJECT: {
        PRJ_MGR: 'PRJ_MGR'
    },

    PRIVILEGE_USER: {
        ADMIN: 'ADMIN',
        OWNER: 'OWNER',
        ADMIN_OWNER: 'ADMIN_OWNER'
    },

    AGENT_FLAG: {
        TRUE: 1,
        FALSE: 0
    },

    ADMIN_MODE: {
        ON: 'ON',
        OFF: 'OFF'
    },

    ROOT_FLAG: {
        TRUE: 1,
        FALSE: 0
    },

    STATUS_SERVICE_PERMISSION: {
        ON: 1,
        OFF: 0
    },

    CORPORATION_CONTRACT_TYPE: {
        ROOT: 'ROOT',
        GUEST: 'GUEST',
        GUEST_DESK: 'GUESTDESK',
        TRIAL: 'TRIAL',
        STANDARD: 'STANDARD',
        PREMIUM: 'PREMIUM',
        TEMPLATE_GUEST: 'TEMPLATE_GUEST',
        TEMPLATE_TRIAL: 'TEMPLATE_TRIAL',
        TEMPLATE_STANDARD: 'TEMPLATE_STANDARD',
        TEMPLATE_PREMIUM: 'TEMPLATE_PREMIUM'
    },

    LANGUAGE_TYPE: {
        JP: 'jp',
        EN: 'en'
    },

    ERROR_CHANGE_USER_ADMIN_WITH_INTERNAL: 'Internalユーザーはアドミン権限を持つことが出来ません。',

    TICKET_USAGE: {
        REQ: 'REQ',
        TASK: 'TASK',
        KNOWLEDGE: 'KNOWLEDGE'
    },

    FORM_USAGE: {
        FORM: 'FORM',
        FIELD: 'FIELD',
        TAG: 'TAG',
        STATUS: 'STATUS',
        SPRINT: 'SPRINT',
        FORM_GLOBAL: 'FORM_GLOBAL',
        FIELD_GLOBAL: 'FIELD_GLOBAL',
        CORP_SHARED_FORM_FIELD: 'CORP_SHARED_FORM_FIELD',
        FORM_USING_TICKET: 'FORM_USING_TICKET'
    },

    FORM_TYPE: {
        FORM_REQ: 'REQ',
        FORM_REQ_QA: 'REQ_QA',
        FORM_REQ_NOTICE: 'REQ_NOTICE',
        FORM_REQ_INVITE_GUEST: 'REQ_INVITE_GUEST',
        FORM_REQ_INVITE_GUESTDESK: 'REQ_INVITE_GUESTDESK',
        FORM_REQ_EXTERNAL_DESK: 'REQ_EXTERNAL_DESK',
        FORM_REQ_INVITE_GUEST_CUSTOMER: 'REQ_INVITE_GUEST_CUSTOMER',
        FORM_REQ_EXTERNAL_CUSTOMER: 'REQ_EXTERNAL_CUSTOMER',
        FILE_FORMAT: 'FILE_FORMAT',
        PAGE_FORMAT: 'PAGE_FORMAT',

        FORM_TASK: 'TASK',
        FORM_KNOWLEDGE: 'KNOWLEDGE',
        FORM_MASTER: 'MASTER',

        FIELD_TEXT: 'TEXT',
        FIELD_KEYWORD: 'KEYWORD',
        FIELD_INT: 'INT',
        FIELD_FLOAT: 'FLOAT',
        FIELD_RICHTEXT: 'RICHTEXT',
        FIELD_URL: 'URL',
        FIELD_DATE: 'DATE',
        FIELD_TIME: 'TIME',
        FIELD_DATE_TIME: 'DATE_TIME',
        FIELD_TAG: 'TAG',
        FIELD_WORKFLOW: 'WORKFLOW',
        FIELD_IFRAME: 'IFRAME'
    },

    STATUS_NO: {
        WAITING: 1,
        TODO: 2,
        DOING: 3,
        DONE: 4,
        PENDING: 5
    },

    FILTER_LIST_FORM_TYPE: {
        FORM_REQ_QA: 'REQ_QA',
        FORM_REQ_NOTICE: 'REQ_NOTICE',
        FORM_TASK: 'TASK',
        FORM_KNOWLEDGE: 'KNOWLEDGE',
        FORM_MASTER: 'MASTER',
        FORM_SYSTEM: 'SYSTEM'
    },

    FORM_DEFINITION: {
        SYSTEM: 'SYSTEM',
        SAMPLE: 'SAMPLE',
        USER: 'USER',
        MASTER: 'MASTER',
        SYSTEM_MASTER_FIELD: 'SYSTEM_MASTER_FIELD'
    },

    FORM_DEFINITION_NAME: {
        SYSTEM: 'システム',
        SAMPLE: 'SysAdmin'
    },

    FORM_VIEW_FOR_CUSTOMER: {
        TRUE: 1,
        FALSE: 0
    },

    FIELD_UI: {
        TEXT: 'Text',
        TEXT_AREA: 'TextArea',
        DATE: 'Date',
        NUMBER: 'Number',
        TAG: 'Tag',
        DROP_FILE: 'DropFile',
        IFRAME: 'Iframe',
        URL: 'URL',

        WORKFLOW: 'Status',
        SELECTOR: 'Selector',
        FIELD_RICHTEXT: 'Rich Text'
    },

    FIELD_UI_SELECT_DEFAULT_ITEM: {
        title: '選択してください',
        value: null,
        isDisplay: true
    },

    SYSTEM_FORM_INVITE_GUEST_NAME: 'サポート対象プロジェクト作成',
    SYSTEM_FORM_INVITE_GUESTDESK_NAME: 'パートナープロジェクト作成',
    SYSTEM_FORM_QA_NAME: '問い合わせ用デフォルトフォーマット ',
    SYSTEM_FORM_EXTERNAL_DESK_REQUEST_NAME: '既存サービスデスクプロジェクトを選ぶ',
    SYSTEM_FORM_GUEST_CUSTOMER_INVITATION_NAME: 'ゲストカスタマー登録',
    SYSTEM_FORM_EXTERNAL_CUSTOMER_REQUEST_NAME: '既存プロジェクトを選ぶ',

    FORM_FILE_UPLOAD_LIST_INPUT_NAME: 'fileUploadList',

    FORM_MANAGEMENT_SCREEN: {
        LIST_FORM: 'formList',
        ADD_FORM: 'addForm',
        EDIT_FORM: 'editForm',
        LIST_FIELD: 'fieldList',
        ADD_FIELD_TAG_ITEM: 'addFieldTagItem',
        ADD_FIELD_STATUS_ITEM: 'addFieldStatusItem',
        FIELD_ADD_TO_FORM_REGION: 'listFieldAddToForm',
        FIELD_IN_PROJECT_REGION: 'listFieldInProject'
    },

    CLIENT_FIELD_TYPE: {
        ADD_NEW_FIELD: 'ADD_NEW_FIELD',
        DUPLICATE_FIELD: 'DUPLICATE_FIELD'
    },

    TAG_FIELD_DEFAULT: {
        IS_MULTIPLE: true,
        SELECT_OPTION_TYPE: 'select',
        CHECK_OPTION_TYPE: 'check',
        RECORD_NUMBER: 5,
        IS_ADD_ITEM: true
    },

    TEXT_FIELD_DEFAULT: {
        MIN_LENGTH: 0,
        MAX_LENGTH: 20000,
        INPUT_FORMAT: ''
    },

    HANDLE_EVENT_TYPE: {
        MULTIPLE_ADD_TAG_FIELD: 'MULTIPLE_ADD_TAG_FIELD',
        SELECT_OPTION_ADD_TAG_FIELD: 'SELECT_OPTION_ADD_TAG_FIELD',
        CHECK_OPTION_ADD_TAG_FIELD: 'CHECK_OPTION_ADD_TAG_FIELD',
        RECORD_NUMBER_ADD_TAG_FIELD: 'RECORD_NUMBER_ADD_TAG_FIELD',
        IS_ADD_ITEM_ADD_TAG_FIELD: 'IS_ADD_ITEM_ADD_TAG_FIELD',
        USER_PERMISSION_CREATE_ITEM_TAG: 'USER_PERMISSION_CREATE_ITEM_TAG'
    },

    SETTING_SELECT_ITEM_TAG_FIELD: {
        DEFAULT: '',
        CUSTOMISE: 'customise',
        MASTER_FORMAT_NAME: 'master_format_name',
        MASTER_KNOWLEDGE: 'master_knowledge',
        AUTO_FILL: 'auto_fill',
        SYSTEM_MASTER: 'system_master'
    },

    DISPLAY_ORDER_TAG_FIELD: {
        DEFAULT: '',
        CUSTOMISE: 'customise',
        DICTIONARY_ORDER: 'dictionary_order',
        HITS_NUMBER_ORDER: 'hits_number_order'
    },

    USER_PERMISSION_CREATE_ITEM_TAG: {
        PROJECT_MANAGEMENT: 'project_management',
        PROJECT_MANAGEMENT_AND_AGENT: 'project_management_and_agent',
        PROJECT_MEMEBER: 'project_member',
        ALL_USER_IN_ORG: 'all_user_in_org',
        ALL_USER: 'all_user'
    },

    STATUS_OF_USER: {
        ACTIVE: 'Active',
        WAITING: 'Waiting',
        REJECT: 'Reject',
        DELETED: 'Deleted',
        NOT_INVITED: 'Not Invited'
    },

    UPDATE_TICKET_METHOD: {
        UPDATE_ASSIGNEE: 1,
        UPDATE_STATUS: 2,
        UPDATE_FIELD_TICKET: 3,
        FORCE_EDIT_ASSIGNEE_AND_STATUS: 4,
        UPDATE_DELETED_AT: 5,
        ARCHIVE_TICKET: 6,
        UPDATE_SHARE_STATUS: 7,
        UPDATE_ANONYMOUS_STATUS: 8,
        UPDATE_BACK_TO_DRAFT: 9,
        BACK_TO_ARRIVAL: 10,
        UPDATE_FOOTPRINT: 11,
        UPDATE_FOOTPRINT_FILE_AND_PAGE_KNOWLEDGE: 12
    },

    UPDATE_CORP_DETAIL_METHOD: {
        UPDATE_DETAIL: 1,
        DESK_SERVICE: 2,
        KNOWLEDGE: 3,
        TASK: 4,
        PUBLIC_SERVICE: 5,
        UPDATE_BASIC_INFO: 6,
        CHAT_AI: 7,
        UPDATE_VIEW_CONTRACT_DETAILS: 8
    },

    CREATE_TICKET_METHOD: {
        CREATE_TICKET: 'CREATE_TICKET',
        CREATE_ATTACHMENT: 'CREATE_ATTACHMENT'
    },

    TIME_TO_LIVE_REQUEST_CREATE_USER: 7 * 24 * 60 * 60 * 1000, // 7 days,
    // TIME_TO_LIVE_REQUEST_CREATE_USER: 5 * 60 * 1000, // 5 min

    CREATE_CONTRACT_EXPIRED_AFTER: 72 * 60 * 60 * 1000, // 3 days
    // CREATE_CONTRACT_EXPIRED_AFTER : 10 * 60 * 1000, // 10 min

    SETTING_FORM_DEFAULT: {
        REQUIRED: false,
        DO_NOT_SHOW: 1,
        READ_ONLY: 2,
        PUBLIC_FOR_CUSTOMER: 3
    },
    TAG_FIELD_OPTION_TYPE: {
        SELECT: 'select'
    },

    SETTING_DATE_FIELD_FORMAT: {
        YYYYMMDD_HHMM: 'yyyymmdd_hhmm',
        YYYYMMDD: 'yyyymmdd',
        YYYYMM: 'yyyymm',
        YYYY: 'yyyy',
        HHMM: 'hhmm'
    },

    COMPONENT_DATETIME_PICKER: {
        DATE_TIME_PICKER: 'date_time_picker',
        DATE_PICKER: 'date_picker',
        TIME_PICKER: 'time_picker'
    },

    LIST_TICKET_TAB: {
        INTERNAL_QA: 'INTERNAL_QA',
        EXTERNAL_QA: 'EXTERNAL_QA',
        CUSTOM_SUPPORT: 'CUSTOM_SUPPORT'
    },

    EXTERNAL_REQUEST_TYPE: {
        DESK_REQUEST: 'DESK_REQUEST'
    },

    NUMBER_FIELD_DEFAULT: {
        UNIT: '',
        UNIT_POSITION_AFTER: 'after',
        UNIT_POSITION_BEFORE: 'before',
        MIN_VALUE: -2000000000,
        MAX_VALUE: 2000000000,
        INTEGER_ONLY: false
    },

    NA: 'NA',

    TICKET_TYPE_FORM_STATUS: {
        ACTIVE: true,
        INACTIVE: false
    },

    URL_REGEX: /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/+#-]*[\w@?^=%&/+#-])?/,
    REGEX_FTP: /^ftp:\/\/(?:[a-zA-Z0-9_-]+(?::[a-zA-Z0-9_-]+)?@)?(?:[a-zA-Z0-9.-]+|\[[0-9a-fA-F:.]+\])(?::\d{1,5})?(?:\/[^\s]*)?$/,
    REGEX_FILE: /file:\/\/\/?[A-Za-z]+/,
    REGEX_NOTES: /(Notes|notes):\/\/[^\s]+/,
    PROTOCOL: {
        HTTP: 'http',
        HTTPS: 'https',
        FTP: 'ftp',
        FILE: 'file',
        NOTES: 'notes'
    },

    FIELD_USING_FORMS: {
        ACTIVE: true,
        INACTIVE: false,
        HAS_VALUE: true,
        NO_VALUE: false
    },
    KNOWLEDGE_PUBLIC_TYPE: {
        INTERNAL: 'INTERNAL',
        EXTERNAL: 'EXTERNAL'
    },
    KNOWLEDGE_PUBLIC_RANGE: {
        ONLY_ME: 'ONLY_ME',
        PROJECT_ONLY: 'PROJECT_ONLY',
        CORPORATION_ONLY: 'CORPORATION_ONLY',
        ALL: 'ALL'
    },

    KNOWLEDGE_VIEW_EDIT_PERMISSION: {
        CREATOR_ONLY: 'CREATOR_ONLY',
        IN_PROJECT: 'IN_PROJECT',
        IN_ORG: 'IN_ORG',
        B2B_CUSTOMER: 'B2B_CUSTOMER',
        B2B_SUPPORT: 'B2B_SUPPORT',
        PUBLIC: 'PUBLIC',
        PROJECT_MANAGER: 'PROJECT_MANAGER',
        CREATOR: 'CREATOR'
    },

    KNOWLEDGE_CHAT_PERMISSION: {
        NO: 'NO',
        IN_PROJECT: 'IN_PROJECT',
        IN_ORG: 'IN_ORG',
        PUBLIC: 'PUBLIC',
        BY_VIEW_PERMISSION: 'BY_VIEW_PERMISSION'
    },

    KNOWLEDGE_ADDITIONAL_PERMISSION_TEMPLATE: {
        orgProject: [],
        guestCustomerProject: [],
        baseProject: [],
        b2bCustomerProject: [],
        b2bHelpdeskProject: []
    },

    KNOWLEDGE_RANGE: {
        PUBLIC: 'PUBLIC'
    },

    NOTIFICATION_SYSTEM_TYPE: {
        SDESK: 'sDesk'
    },

    NOTIFICATION_OBJECT_TYPE: {
        QA: 'QA',
        ORG: 'Org',
        PROJECT: 'Project',
        REQUEST_EXTERNAL_DESK: 'REQUEST_EXTERNAL_DESK',
        NOTICE: 'Notice',
        KNOWLEDGE: 'KNOWLEDGE',
        DELEGATE: 'DELEGATE',
        REQUEST_EXTERNAL_CUSTOMER: 'REQUEST_EXTERNAL_CUSTOMER',
        INVITATION_GUEST_PROJECT: 'INVITATION_GUEST_PROJECT',
        INVITATION_GUEST_DESK_PROJECT: 'INVITATION_GUEST_DESK_PROJECT',
        TASK: 'TASK',
    },

    NOTIFICATION_EVENT_TYPE: {
        TICKET_NEW: 'TICKET_NEW',
        TICKET_UPDATE: 'TICKET_UPDATE',
        UPDATE_PIC: 'UPDATE_PIC',
        ADMIN_ON: 'ADMIN_ON',
        ADMIN_OFF: 'ADMIN_OFF',
        AGENT_ON: 'AGENT_ON',
        AGENT_OFF: 'AGENT_OFF',
        MEMBER_ON: 'MEMBER_ON',
        MEMBER_OFF: 'MEMBER_OFF',
        PM_ON: 'PM_ON',
        PM_OFF: 'PM_OFF',
        MESSAGE_NEW: 'MESSAGE_NEW',
        USER_TYPE_CHANGE: 'USER_TYPE_CHANGE',
        MESSAGE_ATTACHMENT_NEW: 'MESSAGE_ATTACHMENT_NEW',
        MESSAGE_MENTION_TO: 'MESSAGE_MENTION_TO',
        EXCEED_MAX_TICKET: 'EXCEED_MAX_TICKET',
        EXCEED_MAX_GENERAL_USER_WHEN_CREATE_TICKET: 'EXCEED_MAX_GENERAL_USER_WHEN_CREATE_TICKET',
        DELETE_PROJECT: 'DELETE_PROJECT',
        KNOWLEDGE_CHAT_MESSAGE_NEW: 'KNOWLEDGE_CHAT_MESSAGE_NEW',
        MENTION_IN_CHAT_KNOWLEDGE: 'MENTION_IN_CHAT_KNOWLEDGE',
        //Contract
        NOTI_UPDATE_CONTRACT_CREDIT_CARD: 'NOTI_UPDATE_CONTRACT_CREDIT_CARD',
        NOTI_UPDATE_CONTRACT_BANK_TRANSFER: 'NOTI_UPDATE_CONTRACT_BANK_TRANSFER',
        OVER_MAX_RESOURCES: 'OVER_MAX_RESOURCES',

        // Register org
        NOTI_ORG_REGISTERED_TRIAL: 'NOTI_ORG_REGISTERED_TRIAL',
        NOTI_ORG_STARTED_TRIAL: 'NOTI_ORG_STARTED_TRIAL',
        NOTI_YOURS_CONTRACT_HAVE_BEEN_UPDATED: 'NOTI-YOURS-CONTRACT-HAVE-BEEN-UPDATED'
    },

    TYPE_GET_NOTIFICATION_LIST: {
        ALL: 'ALL',
        UNREAD: 'UNREAD'
    },
    NOTIFICATION_SOURCE_OBJECT: {
        TICKET: 'TICKET',
        CHATPOST: 'CHATPOST',
        USER: 'USER'
    },
    NOTIFICATION_REMOVE_AFTER: 7 * 24 * 60 * 60 * 1000, // 7 days,

    VISIBILITY_SETTING_TYPE: {
        CUSTOMER: 'CUSTOMER',
        CUSTOMER_IN_ORG: 'CUSTOMER_IN_ORG',
        CUSTOMER_OUT_ORG: 'CUSTOMER_OUT_ORG',
        PRIVATE: 'PRIVATE',
        PROJECT: 'PROJECT',
        CUSTOM: 'CUSTOM',
        IN_ORG: "IN_ORG"
    },

    CUSTOMER_TYPE: {
        CUSTOMER_IN_ORG: 'CUSTOMER_IN_ORG',
        CUSTOMER_OUT_ORG: 'CUSTOMER_OUT_ORG'
    },

    VIEW_NAME_DEFAULT: 'ビュー',

    STATUS_VIEW: {
        SHOW: 'SHOW',
        HIDDEN: 'HIDDEN'
    },

    VIEW_TYPE: {
        CUSTOM: 'CUSTOM',
        DEFAULT: 'DEFAULT'
    },

    DEFINITION_VIEW: {
        KNOWLEDGE: 'KNOWLEDGE',
        HELPDESK: 'HELPDESK'
    },

    EDIT_VIEW_NAME_ERROR_TYPE: {
        BLANK: 'BLANK',
        DUPLICATE: 'DUPLICATE'
    },

    ACTION_LIST_FORM: {
        ADD_NEW_FORM: 'ADD_NEW_FORM',
        EDIT_FORM: 'EDIT_FORM',
        DUPLICATE_FORM: 'DUPLICATE_FORM'
    },

    KNOWLEDGE_SEARCH_SCREEN: {
        DRILLDOWN: 'drilldown',
        SEARCH_OPTION: 'search_option'
    },

    KNOWLEDGE_SEARCH_OPTION: {},

    SETTING_SEARCH_KEYWORD: {
        AND: 'and',
        OR: 'or',
        MUTIPLE: 'mutiple',
        COMMENT: 'comment',
        SIMILAR: 'similar',
        K_NN: 'k.NN'
    },

    VIEW_DEFAULT: {
        NEW_ARRIVAL: '新着ナレッジ'
    },

    DRILLDOWN_SEARCH_OPTION_SORT_TYPE: {
        CREATED_AT: 'CREATED_AT',
        UPDATED_AT: 'UPDATED_AT',
        LATEST_COMMENT: 'LATEST_COMMENT',
        MOST_COMMENT: 'MOST_COMMENT',
        MOST_SEEN_LATER: 'MOST_SEEN_LATER',
        MOST_FOOTPRINT: 'MOST_FOOTPRINT',
        TITLE_ASC: 'TITLE_ASC',
        TITLE_DESC: 'TITLE_DESC',
        KEYWORD_MATCH: 'KEYWORD_MATCH',
        DOC_BODY_ASC: 'DOC_BODY_ASC',
        DOC_BODY_DESC: 'DOC_BODY_DESC',
        SIMILAR: 'SIMILAR',
        PAGE_NO: 'PAGE_NO',
        SORT_TOTAL_PRIVATE_BOOKMARK_ASC: 'SORT_TOTAL_PRIVATE_BOOKMARK_ASC',
        SORT_TOTAL_PRIVATE_BOOKMARK_DESC: 'SORT_TOTAL_PRIVATE_BOOKMARK_DESC'
    },

    FORMAT_OPTION_SEARCH: {
        FORM: 'form',
        CREATOR: 'creator',
        UPDATOR: 'updator',
        PROXIED_USER: 'proxiedUser',
        PUBLIC_RANGE: 'publicRange',
        SUFFIX_OF_ATTACHMENTS: 'suffixOfAttachments',
        STATUS_FIELD: 'status',
        TAG_FIELD: 'tag',
        PROJECT_ORIGIN: 'projectOrigin',
        PROJECT_TARGET: 'projectTarget',
        LIVE_OR_ARCHIVED: 'liveOrArchived',
        TICKET_TYPE: 'ticketType',
        TICKET_CREATED_PROJECT_ID: 'ticketCreatedProjectId',
        PRIVATE_BOOKMARK: 'privateBookmark',
        SHARED_BOOKMARK: 'sharedBookmark'
    },

    DRILLDOWN_USAGE: {
        SYSTEM: 'SYSTEM',
        STATUS: 'STATUS',
        TAG: 'TAG'
    },

    KNOWLEDGE_DATE_SEARCH_OPTION: {
        TYPE: {
            RANGE: 'RANGE',
            AGO: 'AGO'
        },
        AGO_VALUE: 0,
        AGO_UNIT: {
            WEEK: 'WEEK',
            DAY: 'DAY'
        }
    },

    MASTER_FIELD_AUTO_ADD_DATA: {
        ON: true,
        OFF: false
    },

    MASTER_FIELD_AUTO_FILL: {
        ON: true,
        OFF: false
    },

    MASTER_FIELD_SYSTEM_MASTER_ITEM_TYPE: {
        USER: 'USER',
        PROJECT: 'PROJECT',
        CORPORATION: 'CORPORATION'
    },

    MASTER_FIELD_SYSTEM_MASTER_CORPORATION_TYPE: {
        INUSE: 'INUSE',
        CUSTOMER: 'CUSTOMER',
        SUPPORT: 'SUPPORT'
    },

    MASTER_FIELD_SYSTEM_MASTER_PROJECT_TYPE: {
        INUSE: 'INUSE',
        ALL: 'ALL'
    },

    MASTER_FIELD_SYSTEM_MASTER_USER_TYPE: {
        ALL: 'ALL',
        GENERAL: 'GENERAL',
        ADMIN: 'ADMIN',
        PROJECT_MANAGER: 'PROJECT_MANAGER',
        AGENT: 'AGENT',
        INTERNAL_CUSTOMER: 'INTERNAL_CUSTOMER',
        B2C_CUSTOMER: 'B2C_CUSTOMER',
        GUEST: 'GUEST'
    },

    RELATED_MASTER_FORM_DEFAULT: 'NULL',

    SETTING_PUBLIC_FOR_CUSTOMER: {
        HIDE: 1,
        ONLY_VIEW: 2,
        EDITABLE: 3
    },

    CREATE_TICKET_DIALOG_CONFIRM_TYPE: {
        HELPDESK: 'HELPDESK',
        FORM: 'FORM'
    },

    CREATE_TICKET_DEFAULT_VALUE: {
        INVITE_GUESTDESK: {
            OUTLINE: '弊社サポート窓口を開設しました。',
            CATEGORY: 'パートナープロジェクト作成',
            DESCRIPTION:
                'ヘルプデスクの開設ありがとうございます。\n本チケットに対してチャットで返信可能です。'
        },
        EXTERNAL_DESK_REQUEST: {
            OUTLINE: 'サポート取引よろしくお願いします。',
            CATEGORY: '取引申請'
        },
        INVITE_GUEST: {
            CATEGORY: 'サポート対象プロジェクト作成',
            DESCRIPTION:
                '登録ありがとうございます。\n本チケットに対してチャットで問い合わせ可能です。\nまた、新規の問い合わせ案件ごとに、「新規問い合わせ」ボタンより新たなチケットを発行し、問い合わせを行うなど各種サポートを受けることができます。'
        },
        INVITE_GUEST_CUSTOMER: {
            OUTLINE: '弊社サポート窓口を開設しました。 ',
            CATEGORY: 'ゲストカスタマー登録',
            DESCRIPTION:
                '登録ありがとうございます。\n本チケットに対してチャットで問い合わせ可能です。\nまた、新規の問い合わせ案件ごとに、「新規問い合わせ」ボタンより新たなチケットを発行し、問い合わせを行うなど各種サポートを受けることができます。 '
        },
        REQUEST_EXTERNAL_CUSTOMER: {
            OUTLINE: 'サポート取引よろしくお願いします。',
            CATEGORY: '取引申請'
        }
    },

    CUSTOMER_SIDE_VIEW: {
        IN_CHARGE_QA_VIEW: 'IN_CHARGE_QA_VIEW',
        DRAFT_QA_VIEW: 'DRAFT_QA_VIEW',
        ARCHIVE_QA_VIEW: 'ARCHIVE_QA_VIEW',
        NOTICE_VIEW: 'NOTICE_VIEW',
        INVITATION_VIEW: 'INVITATION_VIEW'
    },

    QA_FILTER_CREATOR: {
        ME: 'ME',
        PROJECT_MEMBER: 'PROJECT_MEMBER',
        ALL: 'ALL'
    },

    QA_FILTER_STATUS: {
        SENT: 'SENT',
        DRAFT: 'DRAFT',
        ARCHIVE: 'ARCHIVE'
    },

    HELPDESK_LIST_TYPE: {
        INTERNAL: 'INTERNAL',
        EXTERNAL: 'EXTERNAL'
    },

    DEFAULT_VIEW_STRUCTURE: {
        keyword: '',
        fields: [],
        tagFields: [],
        statusFields: [],
        options: {
            method: 'and',
            attribute: 'mutiple',
            from: null,
            to: null,
            searchDateType: 'RANGE',
            searchDateAgoValue: 0,
            searchDateAgoUnit: 'DAY',
            otherFields: {
                include: [],
                exclude: []
            }
        },
        visibility: 'PRIVATE'
    },

    TICKET_DATA_TYPE: {
        TICKET: 'TICKET',
        FIELD: 'FIELD'
    },

    TICKET_PROPERTY: {
        SENT: 'SENT',
        DRAFT: 'DRAFT',
        ARCHIVE: 'ARCHIVE',
        DELETE: 'DELETE',
        SYNONYM: 'SYNONYM'
    },

    TICKET_DATA_ID: {
        OUTLINE: 'outline',
        DESCRIPTION: 'description',
        MEMO: 'memo',
        ATTACHMENT: 'attachment',
        SUFFIX_OF_ATTACHMENTS: 'suffixOfAttachments',
        KNOWLEDGE_CANVAS: 'knowledgeCanvas',
        ENQUETE: 'enquete',
        ENQUETECOMMENT: 'enqueteComment',
        CUSTOMER_NOTICE: 'customerNotice',
        TICKET_ID: 'ticketId'
    },

    GSI: {
        CHAT_THREAD_ID_AND_CREATED_AT_INDEX: 'ChatThreadIdAndCreatedAtIndex',
        TICKET_ID_AND_CREATED_AT_INDEX: 'TicketIdAndCreatedAtIndex',
        ORGANIZATION_DOMAIN_INDEX: 'OrganizationDomainIndex',
        FORM_GSI_1: 'FormGSI1',
        FORM_GSI_2: 'FormGSI2',
        NOTIFICATION_GSI_1: 'NotificationGSI1',
        PROJECT_GSI_1: 'ProjectGSI1',
        PROJECT_GSI_2: 'ProjectGSI2',
        PROJECT_GSI_3: 'ProjectGSI3',
        TICKET_GSI_1: 'TicketGSI1',
        TICKET_GSI_2: 'TicketGSI2',
        TICKET_GSI_3: 'TicketGSI3',
        CORPORATION_ID_INDEX: 'CorporationIdIndex',
        USER_EMAIL_INDEX: 'UserEmailIndex',
        VIEW_GSI_1: 'ViewGSI1',
        QUEUE_GSI_1: 'QueueGSI1',
        SCAN: 'SCAN',
        BOOKMARK_GSI_1: 'BookmarkGSI1',
        BOOKMARK_GSI_2: 'BookmarkGSI2',
        USER_BOOKMARK_GSI_1: 'UserBookmarkGSI1',
        USER_BOOKMARK_GSI_2: 'UserBookmarkGSI2',
        USER_BOOKMARK_GSI_3: 'UserBookmarkGSI3',
    },

    ANONYMOUS_QA_PAGE: {
        CREATE_ANONYMOUS_QA: 'CREATE_ANONYMOUS_QA',
        CONFIRM_ANONYMOUS_QA: 'CONFIRM_ANONYMOUS_QA',
        SEND_ANONYMOUS_QA_SUCCESS: 'SEND_ANONYMOUS_QA_SUCCESS'
    },
    ANONYMOUS_USER_ID: 'anonymous',
    ANONYMOUS_TICKET_STATUS: {
        INPROGRESS: 0,
        WAITING_APPROVE: 1,
        APPROVE: 2,
        REJECTED: 3,
        CHAT_NOW: 4,
        END_LIVE_CHAT: 5,
        END_LIVE_CHAT_AGENT_NOT_RESPONSE: 6,
        END_LIVE_CHAT_CASE_TICKET_WAITING: 7,
        INPROGRESS_CREATE_TICKET: 8 // Creating ticket(crawling data when create ticket by csv,....)
    },

    TICKET_LIST_PAGINATION_DEFAULT_RECORD: 20,
    DELETE_TICKET_AFTER_TIME: 30,

    CHAT_MENTION_ALL: {
        id: 'ALL',
        name: 'all',
        mail: 'Everyone in this space',
        avatar: '/assets/image/mention-all.svg'
    },
    CHAT_PAGINATION_DEFAULT_RECORD: 30,
    MAX_EMOJI_PER_CHAT_POST: 20,
    MAX_REACTION_BY_USER: 10,
    MAX_REACTION_TOOLTIP_USERNAME: 5,

    B2B_CUSTOMER_PROJECT_STATUS: {
        ACTIVE: 'Active',
        WAITING: 'Waiting',
        REJECT: 'Reject',
        DELETED: 'Deleted'
    },

    EMAIL_MAX_LENGTH: 128,
    TRIGGER_TIMEOUT_WHEEL_END: 500,
    SCROLL_LEFT_CHANGE: 50,
    WIDTH_CHECK_DRAG_END: 4,
    LIST_VIEW_TICKET_HELP_DESK: 4,
    LIST_VIEW_TAB: 4,

    NAME_TAG_FIELD_MAX_LENGTH: 50,

    NOTICE_ALL_CUSTOMER: {
        ALL_CUSTOMER_PROJECT: 'AllCustomerProject',
        ALL_GUEST_CUSTOMER_USER: 'AllGuestCustomerUser',
        ALL_GENERAL_PROJECT: 'AllGeneralProject',
        ALL_USER_IN_BASE_PROJECT: 'AllUserInBaseProject'
    },

    NOTICE_GROUP_CUSTOMER: {
        ALL_CUSTOMER_PROJECT: 'ALL_CUSTOMER_PROJECT',
        ALL_GUEST_CUSTOMER_USER: 'ALL_GUEST_CUSTOMER_USER',
        ALL_GENERAL_PROJECT: 'ALL_GENERAL_PROJECT',
        ALL_USER_IN_BASE_PROJECT: 'ALL_USER_IN_BASE_PROJECT'
    },

    TIME_TO_LIVE_30_DAYS: 2592000000,

    INVITE_GUEST_CONTRACT_EXPIRE_AFTER: 72 * 60 * 60 * 1000, // 72h

    PROJECT_USER_TAB_MANAGEMENT: {
        TAB_USER_PROJECT_LIST: 'userProjectList',
        TAB_B2C_CUSTOMER_MANAGEMENT: 'b2CCustomerManagement',
        TAB_B2B_CUSTOMER_MANAGEMENT: 'b2bCustomerManagement'
    },

    CORPORATION_CUSTOMER_TAB_MANAGEMENT: {
        TAB_CUSTOMER_LIST_CORPORATION: 'TAB_CUSTOMER_LIST_CORPORATION'
    },

    NO_PROJECT: {
        VALUE: 'NO_PROJECT',
        DISPLAY: '未登録プロジェクト'
    },

    NONAME: '無名',
    UPDATOR_SYSTEM: 'System',
    RESTORE_USER_TYPE: {
        USER_IN_ORG: 'USER_IN_ORG',
        B2C_IN_ORG: 'B2C_IN_ORG',
        B2C_IN_PROJECT: 'B2C_IN_PROJECT'
    },

    COGNITO_USER_STATUS: {
        UNCONFIRMED: 'UNCONFIRMED',
        CONFIRMED: 'CONFIRMED',
        ARCHIVED: 'ARCHIVED',
        UNKNOWN: 'UNKNOWN',
        RESET_REQUIRED: 'RESET_REQUIRED',
        FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD'
    },

    COMPONENT_NAME: {
        DETAIL_TICKET_DRAFT: 'DetailTicketDraft',
        DETAIL_TICKET_KNOWLEDGE: 'DetailTicketKnowledge',
        DETAIL_TICKET_PDF_KNOWLEDGE: 'DetailTicketPdfKnowledge',
        SETTING_CHAT_BOT: 'SettingChatbot',
        QA_CATEGORY_SETTING: 'QA_CategorySetting',
        PREVIEW_CHATBOT: 'PreviewChatBot',
        TICKET_LIST: 'TicketList',
        ADD_TICKET: 'AddTicket',
        CHAT_THREAD: 'ChatThread',
        CHAT_THREAD_KNOWLEADGE: 'ChatThreadKnowledge',
        DETAIL_TICKET: 'DetailTicket',
        FORM_MANAGEMENT: 'FormManagement',
        USER_MANAGEMENT: 'UserManagement',
        USER_PROFILE: 'UserProfile',
        ORGANIZATION_PROFILE: 'OrganizationProfile',
        PROJECT_MANAGEMENT: 'ProjectManagement',
        PROJECT_PROFILE_MANAGEMENT: 'ProjectProfileManagement',
        PROJECT_USER_MANAGEMENT: 'ProjectUserManagement',
        PROJECT_CUSTOMER_MANAGEMENT: 'ProjectCustomerManagement',
        CORPORATION_CUSTOMER_MANAGEMENT: 'CorporationCustomerManagement',
        LIST_TICKET_KNOWLEDGE: 'ListTicketKnowledge',
        NOTIFICATION_LIST: 'NotificationList',
        DRILLDOWN_AND_DETAIL_SEARCH: 'DrilldownAndDetailSearch',
        PROJECT_SETTING_IFRAME: 'ProjectSettingIframe',
        ROOT_LIST_ORG: 'RootListOrg',
        PUBLIC_KNOWLEDGE_SITE_SETTING: 'PublicKnowledgeSiteSetting',
        KNOWLEDGE_UPLOAD_CSV: 'knowledgeUploadCsv',
        EDIT_ORG_CONTRACT: 'editOrgContract',
        NORMAL_VIEW_CONTRACT_DETAIL: 'NormalViewContractDetail',
        SETTING_AI: 'SettingAI',
        OPEN_CHAT_AI: 'OpenChatAI'
    },
    DUMMY_DRILLDOWN_STATUS_ID: 'drilldown_status_field',
    DUMMY_PREFIX_DRILLDOWN_STATUS_ITEM_ID: 'drilldown_status_item_',
    DELETED_USER_NAME: 'Deleted User',

    KNOWLEDGE_TICKET_CHAT_PERMISSION_TEXT: {
        NO: 'チャットスレッドを設定しない',
        IN_PROJECT: 'プロジェクト内',
        IN_ORG: '組織内',
        PUBLIC: '一般公開',
        BY_VIEW_PERMISSION: 'ナレッジの閲覧権限に追従する'
    },

    KNOWLEDGE_TICKET_EDIT_PERMISSION_TEXT: {
        CREATOR_ONLY: '投稿者',
        PROJECT_MANAGER: 'プロジェクトマネージャー',
        IN_PROJECT: 'プロジェクト内',
        IN_ORG: '組織内',
        B2B_CUSTOMER: '全B2Bカスタマープロジェクト',
        B2B_SUPPORT: '全B2Bサポートプロジェクト',
        PUBLIC: 'SolutionDeskのユーザー全員',
        BY_VIEW_PERMISSION: 'ナレッジの閲覧権限に追従する'
    },

    KNOWLEDGE_TICKET_SHARE_STATUS: {
        CREATOR_ONLY: '投稿者',
        IN_PROJECT: 'プロジェクト内',
        IN_ORG: '組織内',
        B2B_CUSTOMER: '全B2Bカスタマープロジェクト',
        B2B_SUPPORT: '全B2Bサポートプロジェクト',
        PUBLIC: '一般公開'
    },

    KNOWLEDGE_TICKET_SHARE_STATUS_DETAIL_SETTING: {
        DETAIL_SETTING: '[詳細設定]'
    },

    KNOWLEDGE_SHARE_STATUS_LABEL: '公開範囲',
    KNOWLEDGE_SHARE_STATUS_LABEL_ADDITIONAL: '詳細設定',

    KNOWLEDGE_TICKET_VIEW_PERMISSION_TEXT: {
        CREATOR: '投稿者',
        IN_PROJECT: 'プロジェクト内',
        IN_ORG: '組織内',
        B2B_CUSTOMER: '全B2Bカスタマープロジェクト',
        B2B_SUPPORT: '全B2Bサポートプロジェクト',
        PUBLIC: '一般公開'
    },

    STATUS_OF_ORG: {
        ACTIVE: 'Active',
        WAITING: 'Waiting',
        REJECT: 'Reject',
        BLOCK: 'Block'
    },

    SUFFIX_TEXT: {
        DELETED_USER: '削除済み',
        BLOCK_CORPORATION: '停止中',
        DELETED_CORPORATION: '削除済み'
    },

    TICKET_DELETED_STATUS: {
        REMOVED: 'REMOVED',
        INUSED: 'INUSED',
        TERM_REMOVED: 'TERM_REMOVED'
    },

    DOCUMENT_TITLE: {
        DEFAULT: 'SolutionDesk',
        ORGANIZATION_CREATE: '組織の新規登録 - SolutionDesk',
        ORGANIZATION_SUCCESS: '組織登録成功 - SolutionDesk',
        ORGANIZATION_CONFIRM: '組織登録情報の確認 - SolutionDesk',

        LOGIN: 'ログイン',
        GUEST_CONTRACT_CREATE: 'ゲスト契約の登録 - SolutionDesk',
        GUEST_CONTRACT_APPROVE: 'ゲスト契約でプロジェクト生成依頼の確認 - SolutionDesk',
        GUEST_CONTRACT_SUCCESS: 'ゲスト契約登録成功 - SolutionDesk',

        GUEST_PROJECT_APPROVE: 'ゲスト契約でプロジェクト生成依頼の承認 - SolutionDesk',
        GUEST_PROJECT_REJECT: 'ゲスト契約でプロジェクト生成依頼の却下 - SolutionDesk',
        FORGOT_PASSWORD: 'パスワードの再設定 - SolutionDesk',
        CONFIRM_FORGOT_PASSWORD: 'パスワードの確認 - SolutionDesk',
        IFRAME_LOGIN: 'ログイン',
        IFRAME_ANONYMOUS_QA: '未登録ユーザーの問合せ作成'
    },

    UPDATE_PROJECT_RESOURCE_METHOD: {
        CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
        EDIT_PROJECT: 'EDIT_PROJECT',
        CREATE_AND_EDIT_PROJECT: 'CREATE_AND_EDIT_PROJECT',
        DELETE_PROJECT: 'DELETE_PROJECT',
        DELETE_B2B_CUSTOMER: 'DELETE_B2B_CUSTOMER',
        SETTING_CHATBOT: 'SETTING_CHATBOT',
        SETTING_PUBLIC_KNOWLEDGE_SITE: 'SETTING_PUBLIC_KNOWLEDGE_SITE',
        SETTING_AI: 'SETTING_AI',
        UPDATE_PROJECT_PROFILE: 'UPDATE_PROJECT_PROFILE',
        BASIC_PROMPT_VALIDATE: 'BASIC_PROMPT_VALIDATE'
    },
    ATTACH_FILE_IMAGE_EXTENSIONS: 'tiff,tif,jpeg,jpg,jpe,jfif,jfi,jif,bmp,gif,png',

    ATTACH_FILE: {
        SIZE_SMALL: 'small',
        SIZE_MEDIUM: 'medium',
        VIEW_TICKET: 'ticket',
        VIEW_CHAT: 'chat',
        VIEW_TICKET_DETAIL: 'ticket_detail',
        VIEW_PUBLIC_KNOWLEDGE: 'ticket_public_knowledge'
    },

    QUEUE_TYPE: {
        USER: {
            AUTO_REJECT: 'AUTO_REJECT'
        },
        THREAD: {
            DELETE_TIME_OUT_SUPPORT_THREAD: 'DELETE_TIME_OUT_SUPPORT_THREAD'
        },
        TICKET: {
            DISABLED_TIMEOUT_CHAT_NOW: 'DISABLED_TIMEOUT_CHAT_NOW'
        },
        STORAGE: {
            UPDATE_STATUS_UPLOAD_CSV: 'UPDATE_STATUS_UPLOAD_CSV'
        }
    },

    QUEUE_STATUS: {
        WAITING: 1,
        DONE: 2
    },

    KNOWLEDGE_FORM_CREATE_TICKET_FOR_OWNER_PERMISSION_TEXT: {
        IN_PROJECT: 'プロジェクト内',
        IN_ORG: '組織内',
        B2B_CUSTOMER: '全B2Bカスタマ―プロジェクト ',
        B2B_SUPPORT: '全B2Bサポートプロジェクト',
        ALL_ORG: '全組織'
    },

    GET_API_PUBLIC_TYPE: {
        PREPARE_DATA_APPROVE_PAGE: 'PREPARE_DATA_APPROVE_PAGE',
        PREPARE_DATA_CONFIRM_ORGANIZATION: 'PREPARE_DATA_CONFIRM_ORGANIZATION',
        PREPARE_DATA_IFRAME_LOGIN: 'PREPARE_DATA_IFRAME_LOGIN',
        PREPARE_DATA_ANONYMOUS_QA: 'PREPARE_DATA_ANONYMOUS_QA',
        VERIFY_CREATE_TICKET_ANONYMOUS_QA: 'VERIFY_CREATE_TICKET_ANONYMOUS_QA',
        VERIFY_CONFIRM_TICKET_ANONYMOUS_QA: 'VERIFY_CONFIRM_TICKET_ANONYMOUS_QA',
        GET_FIELD_IN_FORM_FOR_ANONYMOUS_QA: 'GET_FIELD_IN_FORM_FOR_ANONYMOUS_QA',
        PREPARE_DATA_CONFIRM_FORGOT_PASSWORD: 'PREPARE_DATA_CONFIRM_FORGOT_PASSWORD',
        PREPARE_DATA_TICKET_FOR_GUEST_CONTRACT: 'PREPARE_DATA_TICKET_FOR_GUEST_CONTRACT',
        PREPARE_DATA_ORGANIZATION_LOGIN: 'PREPARE_DATA_ORGANIZATION_LOGIN',
        VERIFY_DATA_ORGANIZATION_LOGIN: 'VERIFY_DATA_ORGANIZATION_LOGIN',
        GET_FIELD_CHILD_DATA: 'GET_FIELD_CHILD_DATA',
        VALIDATE_PUBLIC_KNOWLEDGE_URL: 'VALIDATE_PUBLIC_KNOWLEDGE_URL',
        GET_PUBLIC_KNOWLEDGE_DRILLDOWN_DATA: 'GET_PUBLIC_KNOWLEDGE_DRILLDOWN_DATA',
        GET_FORM_BY_ID: 'GET_FORM_BY_ID',
        PREPARE_DATA_LIST_VIEW_TAB_PUBLIC_KNOWLEDGE: 'PREPARE_DATA_LIST_VIEW_TAB_PUBLIC_KNOWLEDGE',
        GET_PUBLIC_KNOWLEDGE_LIST: 'GET_PUBLIC_KNOWLEDGE_LIST',
        GET_PUBLIC_KNOWLEDGE_DETAIL_LIST: 'GET_PUBLIC_KNOWLEDGE_DETAIL_LIST',
        PREPARE_DATA_PUBLIC_KNOWLEDGE_DETAIL: 'PREPARE_DATA_PUBLIC_KNOWLEDGE_DETAIL',
        GET_LIST_MASTER_KNOWLEDGE_OF_FORM: 'GET_LIST_MASTER_KNOWLEDGE_OF_FORM',
        GET_LIST_FORM_OPTIONS_CHATBOT: 'GET_LIST_FORM_OPTIONS_CHATBOT',
        PREPARE_DATA_SETTING_PUBLIC_KNOWLEDGE: 'PREPARE_DATA_SETTING_PUBLIC_KNOWLEDGE',
        GET_LIST_PUBLIC_KNOWLEDGE_CHAT_BOT: 'GET_LIST_PUBLIC_KNOWLEDGE_CHAT_BOT',
        GET_PUBLIC_KNOWLEDGE_DRILLDOWN_DATA_FOR_CHATBOT:
            'GET_PUBLIC_KNOWLEDGE_DRILLDOWN_DATA_FOR_CHATBOT',
        GET_LIST_SUPPORT_CHAT_POT: 'GET_LIST_SUPPORT_CHAT_POT',
        PREPARE_DATA_CHAT_BOT: 'PREPARE_DATA_CHAT_BOT',
        GET_CHAT_BOT_CATEGORY_TAG_FIELD_LIST: 'GET_CHAT_BOT_CATEGORY_TAG_FIELD_LIST',
        GET_CHAT_BOT_CATEGORY_LIST: 'GET_CHAT_BOT_CATEGORY_LIST',
        GET_INFO_FORM_FIELD: 'GET_INFO_FORM_FIELD',
        GET_LIST_CATEGORY_AND_SETTING_CHATBOT: 'GET_LIST_CATEGORY_AND_SETTING_CHATBOT',
        VERIFY_B2C_PASSWORD: 'VERIFY_B2C_PASSWORD',
        GET_PROJECT_ALIAS_AND_SUB_DOMAIN_BY_PROJECT_ID:
            'GET_PROJECT_ALIAS_AND_SUB_DOMAIN_BY_PROJECT_ID',
        GET_FORM_QA_SYSTEM: 'GET_FORM_QA_SYSTEM',
        GET_LIST_AGENT_DATA: 'GET_LIST_AGENT_DATA',
        PREPARE_DATA_REGISTER_TRIAL: 'PREPARE_DATA_REGISTER_TRIAL',
        GET_OUTLINE_FIELD: 'GET_OUTLINE_FIELD'
    },

    UPDATE_API_PUBLIC_TYPE: {
        PUSH_LOG_CLIENT: 'PUSH_LOG_CLIENT',
        ANONYMOUS_END_LIVE_CHAT: 'ANONYMOUS_END_LIVE_CHAT'
    },

    PUBLIC_KNOWLEDGE_PAGE: {
        PAGE_SIZE: 30
    },

    LIMIT_DISPLAY_PAGE: {
        SIZE: 5
    },

    GET_USER_LIST_METHOD: {
        GET_USER_LIST_IN_USER_MANAGEMENT: 'GET_USER_LIST_IN_USER_MANAGEMENT',
        GET_ADMIN_LIST_IN_ORG: 'GET_ADMIN_LIST_IN_ORG'
    },

    PUBLIC_KNOWLEDGE_SORT_TYPE: {
        CREATED_AT: '投稿日新しい順',
        UPDATED_AT: '更新日新しい順',
        TOTAL_PRIVATE_BOOKMARK_ASC: '個人用ブックマーク件数が少ない順',
        TOTAL_PRIVATE_BOOKMARK_DESC: '個人用ブックマーク件数が多い順'
    },

    SETTING_CHAT_BOT: {
        MAX_LENGTH: 20000,
        LOGO_ICON_DEFAULT: '/assets/image/chatbot.svg',
        LOGO_ICON_WHITE: '/assets/image/chatbot-white.svg',
        SEND_LOGO: '/assets/image/icon-send.svg',
        NUMBER_HIT_KNOWLEDGE_TICKET: {
            MAX: 99,
            MIN: 1,
            DEFAULT: 5
        },
        MAX_LENGTH_ANSWER: {
            MIN: 1,
            MAX: 10,
            DEFAULT: 4
        },
        MIN_NUMBER_IN_ANSWER: 1,
        ACTION_WHEN_NOT_RESOLVE: {
            CREATE_TICKET_AND_CHAT_WITH_AGENT: 1, // チケット発行してライブチャットを行う
            CREATE_TICKET_AND_FINISH: 2, // チケット発行して終了する
            DISPLAY_FREE_MESSAGE: 3 // 自由メッセージを表示する
        },
        MAX_OPTION_DISPLAY_LENGTH: {
            MIN: 1,
            MAX: 50,
            DEFAULT: 14
        }
    },
    DEFAULT_CHATBOT_MESSAGE_TYPE: {
        LIST_FORM: 1,
        LIST_TAG_FIELD: 2,
        LIST_TAG_ITEM: 3,
        LIST_TICKET: 4,
        CONFIRM_ISSUES_RESOLVED: 5,
        THE_END: 6,
        FREE_MESSAGE: 7,
        LOADING_GET_LIST_TAG_FIELD: 9,
        SELECT_CATEGORY: 10,
        FORM_QA_IN_CHATBOT: 11,
        BACK_TO_BEGIN: 12,
        CONTENT_QA_CHATBOT: 13,
        SELECT_TAG_ITEM: 14,
        QA_FORM_OPTION_LIVE_CHAT: 15,
        CONNECTING: 16,
        LIVE_CHAT_FORM_ME: 17,
        CONNECTED: 18,
        AGENT_MESSAGE: 19,
        LIVE_CHAT: 20,
        NOT_FOUND_AGENT: 21,
        BACK_TO_BEGIN_AFTER_REJECT: 22,
        FILE_UPLOADED: 23,
        END_LIVE_CHAT: 24,
        LIVE_CHAT_MENTION: 25,
        SELECT_TAG_FIELD: 26,
        USER_SEND_CHAT_AI: 27,
        GPT_RESPONSE_MESSAGE: 28,
        SUGGEST_USING_AI: 29
    },
    MIN_LENGTH_LIST_TICKET_CHATBOT: 5,
    CHATBOT_ACTION: {
        OPEN_CHATBOT: 'OPEN_CHATBOT',
        CLOSE_CHATBOT: 'CLOSE_CHATBOT',
        ZOOMIN_CHATBOT: 'ZOOMIN_CHATBOT',
        ZOOMOUT_CHATBOT: 'ZOOMOUT_CHATBOT',
        FINISHED_RENDER_CHATBOT: 'FINISHED_RENDER_CHATBOT'
    },

    CHATBOT_ACTOR: {
        ANONYMOUS: 'ANONYMOUS',
        AGENT: 'AGENT',
        SYSTEM: 'SYSTEM'
    },

    REMOVE_TICKET_TYPE: {
        LIVE_CHAT: 'LIVE_CHAT'
    },

    TICKET_PREFIX: {
        REQ: 'req_',
        KNOWLEDGE: 'knowledge'
    },

    UPLOAD_CSV_KNOWLEDGE: {
        DEFAULT_LINE: 0,
        MIN_CURRENT_LINE: 2,
        MIN_LAYER: 1,
        // MAX_LAYER:9,
        MAX_LAYER: 2,
        MAX_MB_FILE_UPLOAD: 50,
        STATUS_INITIAL: 'status_initial',
        STATUS_START: 'status_start',
        STATUS_PAUSE: 'status_pause',
        STATUS_SUCCESS: 'status_success',
        DEFAULT_SELECTED_MAPPING: 0,
        FILE_TYPE_DEFAULT: 'pdf,tiff,tif,jpeg,jpg,jfif,jfi,jif,bmp,png',
        MAX_LENGTH_FILE_TYPES: 256,
        MAX_LENGTH_AUTHENTICATION: 128,
        LAYER_SETTINGS: 'layer_settings',
        FILE_TYPE_SETTINGS: 'file_type_settings',
        USER_NAME_SETTINGS: 'user_name_settings',
        PASSWORD_SETTINGS: 'password_settings',
        TYPE_IMPORT_CSV: {
            NO_OVERWRITE: 1,
            KNOWLEDGE_ID: 2,
            FIELD_OF_FORM: 3
        },
        TYPE_OVERWRITE_ATTACH_FILE: {
            UPDATE: 1,
            ADD: 2,
            NO_CHANGE: 3
        },
        AUTO_CREATE_TAG_ITEM: {
            CREATE: 1,
            NOT_CREATE: 0
        }
    },
    INVITED_GENERAL_ID_RANGE: {
        MIN: 1,
        MAX: 999
    },
    DEFAULT_PATH_STYLE_PUBLIC_KNOWLEDGE_SETTINGS: 'assets/public-knowledge-default-style.css',
    DEFAULT_PATH_STYLE_KNOWLEDGE_ROBO_SETTINGS: 'assets/knowledge-robo-default-style.css',
    CHAT_THREAD_MAX_MB_FILE_UPLOAD: 500,
    B2B_INVITATION_STATUS: {
        APPROVED: 'APPROVED',
        WAITING: 'WAITING',
        REJECTED: 'REJECTED'
    },

    CHAT_BOT_ORIGIN: {
        PUBLIC_KNOWLEDGE_SITE: 'PUBLIC_KNOWLEDGE_SITE',
        GEN_SCRIPT: 'GEN_SCRIPT'
    },
    REASON_CAN_NOT_ACCESS_TICKET: {
        TICKET_DELETED: 'isTicketDeleted',
        INVALID_URL: 'isInValidUrl',
        CORP_INACTIVE: 'isCorporationInactive'
    },

    ROOT_CORPORATION: {
        COMPONENT_BASIC_INFO: 'COMPONENT_BASIC_INFO',
        COMPONENT_CONTRACT_DETAILS: 'COMPONENT_CONTRACT_DETAILS',
        TOOL_BAR: {
            CONTRACT_INFO: 'CONTRACT_INFO',
            RESOURCE_USAGE: 'RESOURCE_USAGE',
            OPTION_USAGE: 'OPTION_USAGE',
            PROVIDE_FUNCTION: 'PROVIDE_FUNCTION',
            INVIDUAL_BASIC_SETTING: 'INVIDUAL_BASIC_SETTING'
        },
        RESOURCE_LIST: {
            LABEL_LASTEST_LOGIN_TIME: 'LABEL-LASTEST-LOGIN-TIME',
            LABEL_GENERAL_ID_NUMBER: 'LABEL-GENERAL-ID-NUMBER',
            LABEL_INTERNAL_ID: 'LABEL-INTERNAL-ID',
            LABEL_PARTNER_ID: 'LABEL-PARTNER-ID',
            LABEL_UNASSIGNED_ID: 'LABEL-UNASSIGNED-ID',
            LABEL_INTERNAL_CUSTOMER_ID_NUMBER: 'LABEL-INTERNAL-CUSTOMER-ID-NUMBER',
            LABEL_B2C_ID_NUMBER: 'LABEL-B2C-ID-NUMBER',
            LABEL_B2B_ID_NUMBER: 'LABEL-B2B-ID-NUMBER',
            LABEL_PROJECT_NUMBER: 'LABEL-PROJECT-NUMBER',
            LABEL_GENERAL_PROJECT_NUMBER: 'LABEL-GENERAL-PROJECT-NUMBER',
            LABEL_INTERNAL_DESK_NUMBER: 'LABEL-INTERNAL-DESK-NUMBER',
            LABEL_PUBLIC_DESK_NUMBER: 'LABEL-PUBLIC-DESK-NUMBER',
            LABEL_QA_COUNTER_NUMBER: 'LABEL-Q&A-COUNTER-NUMBER',
            LABEL_SUPPORT_COUNTER_NUMBER: 'LABEL-SUPPORT-COUNTER-NUMBER',
            LABEL_PUBLIC_KNOWLEDGE_SITE_NUMBER: 'LABEL-PUBLIC-KNOWLEDGE-SITE-NUMBER',
            LABEL_KNOWLEDGE_ROBO_NUMBER: 'LABEL-KNOWLEDGE-ROBO-NUMBER',
            LABEL_QA_TICKET_NUMBER: 'LABEL-Q&A-TICKET-NUMBER',
            LABEL_KNOWLEDGE_TICKET_NUMBER: 'LABEL-KNOWLEDGE-TICKET-NUMBER',
            LABEL_FORMAT_NUMBER: 'LABEL-FORMAT-NUMBER',
            LABEL_STORAGE_USAGE_NUMBER: 'LABEL-STORAGE-USAGE-NUMBER'
        },

        OPTION_USAGE: {
            LABEL_ADVANCED_NUMBER_OF_TICKET: 'LABEL-ADVANCED-NUMBER-OF-TICKET',
            LABEL_ADVANCED_NUMBER_OF_KNOWLEDGE: 'LABEL-ADVANCED-NUMBER-OF-KNOWLEDGE',
            LABEL_ADVANCED_STORAGE: 'LABEL-ADVANCED-STORAGE',
            LABEL_ADVANCED_NUMBER_OF_QA_COUNTER: 'LABEL-ADVANCED-NUMBER-OF-QA-COUNTER',
            LABEL_ADVANCED_NUMBER_OF_SUPPORTED_COUNTER: 'LABEL-ADVANCED-NUMBER-OF-SUPPORTED-COUNTER'
        },

        PROVIDE_FUNCTION: {
            LABEL_FUNCTION_INTERNAL_QA_COUNTER_SETTINGS:
                'LABEL-FUNCTION-INTERNAL-Q&A-COUNTER-SETTINGS',
            LABEL_FUNCTION_PUBLIC_SERVICE_DESK_SETTINGS:
                'LABEL-FUNCTION-PUBLIC-SERVICE-DESK-SETTINGS',
            LABEL_FUNCTION_PUBLIC_WEB_FORM: 'LABEL-FUNCTION-PUBLIC-WEB-FORM',
            LABEL_FUNCTION_SOLUTIONDESK_KNOWLEDGE: 'LABEL-FUNCTION-SOLUTIONDESK-KNOWLEDGE',
            LABEL_FUNCTION_CONVERT_PDF: 'LABEL-CONVERT-PDF',
            LABEL_FUNCTION_SYNONYM_SEARCH: 'LABEL-SYNONYM-SEARCH',
            LABEL_BATCH_COMMAND: 'LABEL-BATCH-COMMAND',
            LABEL_FUNCTION_CHAT_AI: 'LABEL-CHAT-AI',
            LABEL_FUNCTION_LINCENSE_KEY_PERMISSION: 'LABEL-LINCENSE-KEY-PERMISSION',
            LABEL_FUNCTION_PUBLIC_KNOWLEDGE_SITE: 'LABEL-FUNCTION-PUBLIC-KNOWLEDGE-SITE',
            LABEL_FUNCTION_KNOWLEDGE_BOT: 'LABEL-FUNCTION-KNOWLEDGE-BOT',
            LABEL_FUNCTION_TASK_MNG: 'LABEL-FUNCTION-TASK-MNG',
            LABEL_FUNCTION_ADVANCED_SETTINGS: 'LABEL-FUNCTION-ADVANCED-SETTINGS',
            LABEL_FUNCTION_LOG_ANALYSIS: 'LABEL-FUNCTION-LOG-ANALYSIS'
        },

        INVIDUAL_BASIC_SETTING: {
            LABEL_BASIC_NUMBER_OF_TICKET: 'LABEL-BASIC-NUMBER-OF-TICKET',
            LABEL_BASIC_NUMBER_OF_KNOWLEDGE: 'LABEL-BASIC-NUMBER-OF-KNOWLEDGE',
            LABEL_BASIC_NUMBER_OF_FORM: 'LABEL-BASIC-NUMBER-OF-FORM',
            LABEL_BASIC_NUMBER_OF_QA_COUNTER: 'LABEL-BASIC-NUMBER-OF-QA-COUNTER',
            LABEL_BASIC_NUMBER_OF_SUPPORTED_COUNTER: 'LABEL-BASIC-NUMBER-OF-SUPPORTED-COUNTER',
            LABEL_BASIC_NUMBER_OF_GENERAL_PRJ: 'LABEL-BASIC-NUMBER-OF-GENERAL-PRJ',
            LABEL_BASIC_NUMBER_OF_INTERNAL_CUSTOMER: 'LABEL-BASIC-NUMBER-OF-INTERNAL-CUSTOMER',
            LABEL_BASIC_NUMBER_OF_B2C: 'LABEL-BASIC-NUMBER-OF-B2C',
            LABEL_BASIC_STORAGE: 'LABEL-BASIC-STORAGE',
            LABEL_BASIC_GENERAL_USER_FEE: 'LABEL-BASIC-GENERAL-USER-FEE',
            LABEL_BASIC_CHARGE: 'LABEL-BASIC-CHARGE'
        },

        INVIDUAL_BASIC_SETTING_VALID: {
            MIN_VALUE: 0,
            MAX_LENGTH: 8
        }
    },

    GET_CORPORATION_CONTRACT_DETAIL_METHOD: {
        GET_CORPORATION_AND_CONTRACT_DATA: 'GET_CORPORATION_AND_CONTRACT_DATA',
        GET_CORPORATION_AND_CONTRACT_DATA_TO_VIEW_AND_UPDATE:
            'GET_CORPORATION_AND_CONTRACT_DATA_TO_VIEW_AND_UPDATE',
        GET_CORPORATION_LIST_USER: 'GET_CORPORATION_LIST_USER'
    },

    AI_SETTING_RANGE: {
        IN_PROJECT: 'IN_PROJECT',
        IN_ORG: 'IN_CORPORATION',
        OUT_ORG: 'OUT_CORPORATION'
    },

    USER_PERMISSION_VIEW_KNOWLEDGE: {
        MEMBER_OF_PROJECT: 'MEMBER_OF_PROJECT',
        PERSONAL_PROJECT: 'PERSONAL_PROJECT',
        B2C: 'B2C',
        GUEST_PARTNER: 'GUEST_PARTNER',
        GUEST_SUPPORTED: 'GUEST_SUPPORTED'
    },

    KNOWLEDGE_VIEW_OPTION: {
        PRJ_IN_USE: 'PRJ_IN_USE',
        ALL_KNOWLEDGE_IN_ORG: 'ALL_KNOWLEDGE_IN_ORG'
    },
    AI_SYSTEM_FIELD_TARGET_OPTION_TAGS: {
        INTERNAL: 'AIPromptApplicationTargetInternal',
        EXTERNAL: 'AIPromptApplicationTargetExternal'
    },
    KNOWLEDGE_SORT_TYPE: {
        CREATED_AT: 'コンテンツの投稿日順',
        UPDATED_AT: 'コンテンツの更新日順',
        SIMILAR: 'コンテンツの類似度順',
        PAGE_NO: 'ページ番号順',
        TOTAL_PRIVATE_BOOKMARK_ASC: '個人用ブックマーク件数が少ない順',
        TOTAL_PRIVATE_BOOKMARK_DESC: '個人用ブックマーク件数が多い順'
    },
    METHOD: 'method',

    AI_PROMPT_SCOPED: {
        INTERNAL_PRJ: 'inPrj',
        EXTERNAL_PRJ: 'outPrj'
    },

    SETTING_AI_PROMPT_ACTION: {
        UPDATED_USAGE_PROMPT: 'promptUsage',
        SETTING_BASIC_PROMPT: 'basicPrompt',
        DELETED_BASIC_PROMPT: 'deletePrompt'
    },

    VECTOR_DATA_STATUS: {
        DOING: 'DOING',
        DONE: 'DONE'
    },
    AI_PROMPT_VALID_CODE: {
        MSG_CM_INVALID_LINK: 'MSG-AI-PASTED-LINK-ARE-NOT-PROMPT-KNOWN'
    },
    AI_PROMPT: {
        AI_PROMPT_BODY: 'AIPromptBody',
        APPLY_AI_PROMPT: 'ApplyAIprompt',
        AI_PROMPT_APPLICATION_TARGET: 'AIPromptApplicationTarget'
    },
    UPDATED_ROOT_ORG_CODE_FAIL: {
        MSG_CM_BLOCKED_ORG: 'MSG-CM-BLOCKED-ORG',
        MSG_CM_DELETED_ORG: 'MSG-CM-DELETED-ORG',
        MSG_VERSION_HAVE_BEEN_DELETED: 'MSG-VERSION-HAVE-BEEN-DELETED'
    },
    SPLIT_PDF_SETTING: {
        ON: 1,
        OFF: 0
    },
    PDF_FUNCTION: {
        ON: 1,
        OFF: 0
    },
    DEFAULT_OUTLINE: '概要(現在未記入)',
    ATTACHMENT_DISPLAY_LIMIT: 3,
    RICHTEXT_MAX_LENGTH: 350000,
    MAX_SIZE_KB_FIELD: 350,
    CHATBOT_ORIGIN: {
        CHATBOT: 'chatbot'
    },
    KEY_FILE_CSS_TYPE: {
        PUBLIC_KNOWLEDGE_SITE: 0,
        KNOWLEDGE_ROBO: 1
    },
    COMMAND_CSV: {
        LINUX_PATH: 'command/sdimport.sh',
        WINDOWS_PATH: 'command/sdimport.ps1',
        README_PATH: 'command/README.md'
    },
    LICENSE_KEY_STATUS: {
        EXPIRED: 'EXPIRED',
        USING: 'USING'
    },
    NOT_ROOT_SETTING_KEY: '**********',

    TASK_STATUS: {
        WAITING: 0,
        START: 1,
        DONE: 2,
        CRAWLING: 3
    },

    BULK_ACTION: {
        EDIT_TAG_FIELD: 'EDIT_TAG_FIELD',
        EDIT_PUBLIC_RANGE: 'EDIT_PUBLIC_RANGE',
        EXPORT_CSV: 'EXPORT_CSV',
        BACK_TO_DRAFT: 'BACK_TO_DRAFT',
        SENT_TO_PUBLIC: 'SENT_TO_PUBLIC',
        DUPLICATE: 'DUPLICATE',
        RESTORE: 'RESTORE',
        DELETE: 'DELETE',
        SOFT_DELETE: 'SOFT_DELETE',
        HARD_DELETE: 'HARD_DELETE',
        KNOWLEDGE_BOOKMARK_TICKET: 'KNOWLEDGE_BOOKMARK_TICKET',

        HELPDESK_EXPORT_CSV: 'HELPDESK_EXPORT_CSV',
        HELPDESK_BACK_TO_DRAFT: 'HELPDESK_BACK_TO_DRAFT',
        HELPDESK_SENT_TO_PUBLIC: 'HELPDESK_SENT_TO_PUBLIC',
        HELPDESK_DELETE: 'HELPDESK_DELETE',
        HELPDESK_RESTORE: 'HELPDESK_RESTORE',
        HELPDESK_SOFT_DELETE: 'HELPDESK_SOFT_DELETE',
        HELPDESK_HARD_DELETE: 'HELPDESK_HARD_DELETE',
        HELPDESK_ARCHIVE: 'HELPDESK_ARCHIVE',
        HELPDESK_BACK_TO_NEW_SUPPORT: 'HELPDESK_BACK_TO_NEW_SUPPORT',
        HELPDESK_EDIT_TAG_FIELD: 'HELPDESK_EDIT_TAG_FIELD'
    },

    BULK_ACTION_LIST: [
        { type: 'EDIT_TAG_FIELD', disable: false },
        { type: 'EDIT_PUBLIC_RANGE', disable: false },
        { type: 'EXPORT_CSV', disable: true },
        { type: 'BACK_TO_DRAFT', disable: false },
        { type: 'SENT_TO_PUBLIC', disable: true },
        { type: 'DUPLICATE', disable: true },
        { type: 'DELETE', disable: false },
        { type: 'RESTORE', disable: false }
    ],

    HELPDESK_BULK_ACTION_LIST: [
        { type: 'HELPDESK_EDIT_TAG_FIELD', disable: true },
        { type: 'HELPDESK_EXPORT_CSV', disable: true },
        // { type: 'HELPDESK_BACK_TO_DRAFT', disable: false },
        // { type: 'HELPDESK_SENT_TO_PUBLIC', disable: true },
        { type: 'HELPDESK_DELETE', disable: false },
        { type: 'HELPDESK_RESTORE', disable: false },
        { type: 'HELPDESK_ARCHIVE', disable: false },
        { type: 'HELPDESK_BACK_TO_NEW_SUPPORT', disable: false }
    ],

    BULK_ACTION_ERROR_LIST: {
        MSG_CM_CONTENT_NOT_EXIST: 'MSG-CM-CONTENT-NOT-EXIST',
        MSG_KNOW_EDIT_PERMISSION_EXCEED_VIEW_PERMISSION:
            'MSG-KNOW-EDIT-PERMISSION-EXCEED-VIEW-PERMISSION',
        MSG_CM_TAG_FIELD_NOT_EXIST: 'MSG-CM-TAG-FIELD-NOT-EXIST',
        MSG_CM_TAG_ITEM_NOT_EXIST: 'MSG-CM-TAG-ITEM-NOT-EXIST',
        MSG_CM_DO_NOT_HAVE_PERMISSION: 'MSG-CM-DO-NOT-HAVE-PERMISSION',
        MSG_CM_PUBLIC_RANGE_CAN_NOT_SET: 'MSG-CM-PUBLIC-RANGE-CAN-NOT-SET',
        MSG_CM_REQUIRED_TAG_FIELD_EMPTY: 'MSG-CM-REQUIRED-TAG-FIELD-EMPTY',
        MSG_CM_CONTENT_CAN_NOT_PUBLIC: 'MSG-CM-CONTENT-CAN-NOT-PUBLIC'
    },

    LIST_PERMISSION_PUBLIC_RANGE: {
        BASIC_VIEW: '基本閲覧権限',
        BASIC_EDIT: '基本編集権限',
        ADDITIONAL_VIEW: '追加閲覧権限',
        ADDITIONAL_EDIT: '追加編集権限'
    },
    LIST_GROUP_ROLE_PUBLIC_RANGE: {
        BASIC_VIEW: 'BASIC_VIEW',
        BASIC_EDIT: 'BASIC_EDIT',
        PRJ_IN_ORG: 'PRJ_IN_ORG',
        B2B_CUSTOMER: 'B2B_CUSTOMER',
        B2B_SUPPORT: 'B2B_SUPPORT',
        PRJ_OTHER: 'PRJ_OTHER'
    },
    PUBLIC_RANGE_ROLE: {
        IN_ORG: 'orgProject',
        B2B_CUSTOMER: 'b2bCustomerProject',
        B2B_HELPDESK: 'b2bHelpdeskProject',
        BASE_PRJ: 'baseProject',
        GUEST_CUSTOMER_PRJ: 'guestCustomerProject'
    },
    LIMIT_TICKET_UPDATE_MULTI: 10000,
    LIMIT_TICKET_EXPORT: 100000,
    BULK_ACTION_EXPORT_CHAT_THREAD: {
        ON: '1',
        OFF: '0'
    },
    BULK_ACTION_EXPORT_FILE_TYPE: {
        JSON: 'json',
        CSV: 'csv'
    },
    BULK_ACTION_LOG_TICKET_TYPE: {
        KNOWLEDGE: 'KNOWLEDGE',
        HELPDESK: 'HELPDESK'
    },

    QUERY_ELS_ACTION_TYPE: { GET_MATER_KNOWLEDGE_OF_FORM: 'GET_MATER_KNOWLEDGE_OF_FORM' },

    PUSH_LOG_CLIENT_ACTION: {
        CREATE_COOKIE: 'CREATE_COOKIE'
    },

    RESET_ATOM: {
        BULK_ACTION_KNOWLEDGE_TASK_ID: 'bulkActionKnowledgeTaskIdAtom',
        BULK_ACTION_HELPDESK_TASK_ID: 'bulkActionHelpdeskTaskIdAtom',
        PUBLIC_KNOWLEDGE_HIT_NUMBER_TICKET: 'publicKnowledgeHitNumberTicketAtom'
    },

    EMAIL_RESPONSE_MODE: {
        ON: 1,
        OFF: 0
    },
    PANEL_SIZE: {
        SIZE_1: 'SIZE_1',
        SIZE_2: 'SIZE_2',
        SIZE_3: 'SIZE_3',
    },

    NOTI_MAIL_CODE: {
        NOTI_MAIL_CREATE_TICKET_TASK: 'NOTI_MAIL_CREATE_TICKET_TASK',
        NOTI_MAIL_EDIT_TICKET_TASK: 'NOTI_MAIL_EDIT_TICKET_TASK',
        NOTI_MAIL_CHAT_TICKET_TASK: 'NOTI_MAIL_CHAT_TICKET_TASK',
        NOTI_MAIL_MENTION_TO_IN_TICKET_TASK: 'NOTI_MAIL_MENTION_TO_IN_TICKET_TASK'
    },

    PANEL_RESPONSIVE: {
        PC: 1367,
        MOBILE: 926,
    },

    DRILLDOWN_TICKET_TYPE: {
        NOTICE: 'お知らせ',
        QA: '問い合わせ',
        DELEGATE: '問い合わせ代理発行',
        INVITATION: 'サポート窓口追加',
        TASK: 'タスク'
    },

    DRILLDOWN_TYPE_TEXT: {
        TICKET_TYPE: 'ticketType'
    },
    IFRAME_SETTING: {
        COMPLETED_SEND_TICKET_MESSAGE: 'messageCompletedSendTicket',
        COMPLETED_SEND_TICKET_SUBJECT_MAIL: 'subjectMailCompletedSendTicket',
        COMPLETED_SEND_TICKET_CONTENT_MAIL: 'contentMailCompletedSendTicket'
    },

    NOTIFICATION_SETTING_RECEIVED_TYPE: {
        TICKET: 'ticket',
        CHAT_MSG: 'chatMsg'
    },

    SCREEN_DEVICE: {
        MOBILE: 'mobile',
        TABLET: 'tablet',
        DESKTOP: 'desktop',
    },

    DEFAULT_BOOKMARK_NAME: 'あとで見る',
    BOOKMARK_DEFINITION: {
        SYSTEM: "SYSTEM",
        USER: 'USER'
    },

    BOOKMARK_UPDATE_API_ACTION: {
        ADD_DEFAULT_BOOKMARK_IN_TICKET_LIST: 'ADD_DEFAULT_BOOKMARK_IN_TICKET_LIST',
        REMOVE_DEFAULT_BOOKMARK_IN_TICKET_LIST: 'REMOVE_DEFAULT_BOOKMARK_IN_TICKET_LIST',
        ADD_AND_UPDATE_MULTI_BOOKMARK: 'ADD_AND_UPDATE_MULTI_BOOKMARK'
    },

    BOOKMARK_GET_API_ACTION: {
        GET_ALL_BOOKMARK_USER_CAN_USE: 'GET_ALL_BOOKMARK_USER_CAN_USE',
        GET_ALL_PRIVATE_BOOKMARK_OF_USER: 'GET_ALL_PRIVATE_BOOKMARK_OF_USER',
        GET_ALL_SHARE_BOOKMARK_OF_PROJECT: 'GET_ALL_SHARE_BOOKMARK_OF_PROJECT',
        GET_ALL_SHARE_BOOKMARK_OF_USER: 'GET_ALL_SHARE_BOOKMARK_OF_USER',
    },

    BOOKMARK_SHARE_RANGE: {
        PRIVATE: 1,
        SHARE_IN_PROJECT: 2
    },

    UPDATE_ELS_FLAG: {
        DEFAULT: 0,
        EVENT: 1,
        REALTIME: 2,
    },

    BOOKMARK_ACTION: {
        EDIT: 'EDIT',
        SHARE: 'SHARE',
        UNSHARE: 'UNSHARE',
        DELETE: 'DELETE',
    },

    BOOKMARK_PREFIX: {
        TEMP: 'temp',
        USER: 'user',
        PROJECT: 'project'
    },

    BREAKLINE_REGEX: /\r?\n|\r|\n/g,
    IPV4_FORMAT_REGEX: /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,
    NUMBER_REGEX: /^\d+$/,

    TYPE_LAST_FORM: {
        LAST_FORM_QA: 'lastFormQA',
        LAST_FORM_NOTICE: 'lastFormNotice',
        LAST_FORM_DELEGATE: 'lastFormDelegate',
        LAST_FORM_INVITED_QA: 'lastFormInvitedQA',
        LAST_FORM_INVITED: 'lastFormInvited',
        LAST_FORM_TASK: 'lastFormTask',
        LAST_FORM_KNOWLEDGE: 'lastFormKnowledge'
    }
};

export default Constant;
